import { Box, TextField, Typography } from "@mui/material";
import React from "react";

export default function CommentForm() {
  // { data, columns, loadingState }
  return (
    <Box sx={{ width: "100%" }}>
      <Typography sx={{ fontWeight: "bold", color: "#3f3f3f", mb: 2 }}>
        Challenges
      </Typography>
      <TextField
        id="outlined-multiline-static"
        placeholder="Add the challenges here"
        multiline
        rows={4}
        fullWidth
        size="small"
        sx={{ mb: 4 }}
        // defaultValue="Default Value"
      />
      <Typography sx={{ fontWeight: "bold", color: "#3f3f3f", mb: 2 }}>
        Ways Forward
      </Typography>
      <TextField
        id="outlined-multiline-static"
        placeholder="Add the ways forward here"
        multiline
        rows={4}
        fullWidth
        size="small"
        sx={{ mb: 4 }}
        // defaultValue="Default Value"
      />
    </Box>
  );
}
