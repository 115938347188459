import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { number, object, string } from "yup";
import TextFieldDatePicker from "components/smea/Textfields/DatePicker";
import formService from "services/i-abide/form-service";
import dayjs from "dayjs";
import { useStateContext } from "contexts/ContextProvider";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background: "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.4)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function SubmitFormModal({
  open,
  handleClose,
  updateTableFunction,
}) {
  const { auth } = useStateContext();
  const [ampm, setAmPm] = useState("AM");

  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      requirements: "",
      activity_Program: "",
      link_to_memo: "",
      title_of_memo: "",
      posting_date: "",
      due_date: "",
      due_time: "",
      posted_by: "",
      role: "",
      officeId: auth.officeId,
      folderName: "",
      subDir: "",
    },

    validationSchema: object().shape({
      requirements: string().required("Required"),
      activity_Program: string().required("Required"),
      link_to_memo: string().required("Required"),
      title_of_memo: string().required("Required"),
      posting_date: string().required("Required"),
      due_date: string().required("Required"),
      due_time: string().required("Required"),
      posted_by: string().required("Required"),
      role: string().required("Required"),
      officeId: number().required("Required"),
      folderName: string().required("Required"),
      subDir: string().required("Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      setError("");

      const formikValues = {
        ...values,
        due_time: `${values.due_time} ${ampm}`,
        posting_date: dayjs(values.posting_date).format("YYYY-MM-DD"),
        due_date: dayjs(values.due_date).format("YYYY-MM-DD"),
      };

      formService
        .formSubmit(formikValues)
        .then((res) => {
          alert("Successfully added your post");
          formik.resetForm();
          updateTableFunction();
          console.log(res.data);
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    const areAllValuesFilled = Object.values(formik.values).every(
      (value) => !!value
    );

    setDisabled(areAllValuesFilled);
  }, [formik.values]);

  const handleTimeChange = (event) => {
    let input = event.target.value;

    // Remove any non-numeric characters
    input = input.replace(/[^\d]/g, "");

    // Insert ":" after the first two digits
    if (input.length === 2) {
      input = `${input}:`;
    } else if (input.length > 2) {
      input = `${input.slice(0, 2)}:${input.slice(2)}`;
    }

    // Update the field value
    formik?.setFieldValue("due_time", input);
  };

  const toggleAmPm = () => {
    setAmPm((prevAmPm) => (prevAmPm === "AM" ? "PM" : "AM"));
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box
        variant="form"
        component="form"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        sx={style}
      >
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundColor: "#2a9618",
              borderRadius: "10px",
              width: "50%",
              ml: "3.5%",
              p: 1,
              mb: 1,
            }}
          >
            <Typography
              sx={{ ml: 3.5, fontSize: 18, fontWeight: "bold", color: "#fff" }}
            >
              UPLOADING FORM
            </Typography>
          </Box>
          {error}
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 4,
              mx: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="requirements"
                  label="Requirements"
                  size="small"
                  disabled={loading}
                  value={formik.values.requirements}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.requirements &&
                    Boolean(formik.errors.requirements)
                  }
                  helperText={
                    formik.touched.requirements && formik.errors.requirements
                  }
                  variant="outlined"
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="activity_Program"
                  label="Activity/Program/Project"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik.values.activity_Program}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.activity_Program &&
                    Boolean(formik.errors.activity_Program)
                  }
                  helperText={
                    formik.touched.activity_Program &&
                    formik.errors.activity_Program
                  }
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="link_to_memo"
                  label="Link to Memo"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik.values.link_to_memo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.link_to_memo &&
                    Boolean(formik.errors.link_to_memo)
                  }
                  helperText={
                    formik.touched.link_to_memo && formik.errors.link_to_memo
                  }
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="title_of_memo"
                  label="Title of Memo"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik.values.title_of_memo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.title_of_memo &&
                    Boolean(formik.errors.title_of_memo)
                  }
                  helperText={
                    formik.touched.title_of_memo && formik.errors.title_of_memo
                  }
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="posted_by"
                  label="Posted By"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik.values.posted_by}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.posted_by && Boolean(formik.errors.posted_by)
                  }
                  helperText={
                    formik.touched.posted_by && formik.errors.posted_by
                  }
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="role"
                  label="Role"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldDatePicker
                  id="posting_date"
                  label="Posting Date"
                  disabled={loading}
                  value={formik.values.posting_date}
                  onChange={(evt) =>
                    formik?.setFieldValue("posting_date", evt, true)
                  }
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.posting_date &&
                    Boolean(formik.errors.posting_date)
                  }
                  helperText={
                    formik.touched.posting_date && formik.errors.posting_date
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      color: formik.values.posting_date ? "black" : "gray",
                    },
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldDatePicker
                  id="due_date"
                  label="Due Date"
                  disabled={loading}
                  value={formik.values.due_date}
                  onChange={(evt) =>
                    formik?.setFieldValue("due_date", evt, true)
                  }
                  error={
                    formik.touched.due_date && Boolean(formik.errors.due_date)
                  }
                  helperText={formik.touched.due_date && formik.errors.due_date}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: formik.values.due_date ? "black" : "gray",
                    },
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="due_time"
                  label="Due Time"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik.values.due_time}
                  onChange={handleTimeChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.due_time && Boolean(formik.errors.due_time)
                  }
                  helperText={formik.touched.due_time && formik.errors.due_time}
                  sx={{ pr: 5 }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={toggleAmPm}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          borderLeft: "solid 1px #b2b2b2",
                          height: "60px",
                          mr: -1.5,
                          pr: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {ampm === "AM" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </Box>
                        <span>{ampm}</span>
                      </InputAdornment>
                    ),
                    inputProps: {
                      maxLength: 5, // Limit to HH:MM format
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="folderName"
                  label="Folder Name"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik.values.folderName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.posted_by &&
                    Boolean(formik.errors.folderName)
                  }
                  helperText={
                    formik.touched.posted_by && formik.errors.folderName
                  }
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="subDir"
                  label="Sub Folder"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik.values.subDir}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={formik.touched.subDir && Boolean(formik.errors.subDir)}
                  helperText={formik.touched.subDir && formik.errors.subDir}
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            p: 2,
            zIndex: 10,
          }}
        >
          <Button
            disabled={!disabled}
            type="submit"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: disabled ? "blue" : "#c3c3c3",
              color: "#fff",
              py: 1,
              width: "10vw",
              minWidth: "100px",
              "&:hover": {
                border: "solid 1px gray",
                backgroundColor: "lightgray",
                color: "#2b2b2b",
                fontWeight: "bold",
              },
            }}
          >
            <AddIcon
              sx={{
                mr: 1,
                color: "#fff",
              }}
            />
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
