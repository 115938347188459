/* eslint-disable no-alert */
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStateContext } from "contexts/ContextProvider";
import SnackbarComponent from "components/smea/Snackbar";
import formService from "services/smea/form-service";
import EncodingForm from "./EncodingForm";
import CategorizingForm from "./CategorizingForm";

export default function Form() {
  const { auth } = useStateContext();
  const [schoolDetails, setSchoolDetails] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [competencies, setCompetencies] = useState([]);
  const [competencyDetails, setCompetencyDetails] = useState({
    officeId: auth.officeId,
    gradeLevelId: "",
    quarterId: "",
    subjectId: "",
    schoolYear: "",
    status: 0,
    data: [],
    averageMPS: 0,
    averageSD: 0,
  });

  const [back, setBack] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleCategoriesUpdate = (updatedData) => {
    setCompetencyDetails((prevData) => ({
      ...prevData,
      status: prevData.status,
      gradeLevelId: updatedData.grade,
      quarterId: updatedData.quarter,
      subjectId: updatedData.subject,
      schoolYear: updatedData.schoolYear,
      componentId: updatedData.component,
    }));
  };

  const handleDetailsUpdate = (updatedData) => {
    setCompetencyDetails((prevData) => ({
      ...prevData,
      status: updatedData.status,
      data: updatedData.data.map((item) => ({
        ...item,
        melcId: item?.melcId || undefined,
        mps: item?.mps || undefined,
        sd: item?.sd || undefined,
      })),
      averageMPS: updatedData.mpsAverageTotal,
      averageSD: updatedData.sdAverageTotal,
    }));
  };

  const handleGetCompetencies = (categories) => {
    setLoading(true);
    setError("");

    formService
      .filterMELCS({ ...categories, officeId: auth.officeId })
      .then((e) => {
        if (e.hasEncoded === true) {
          alert("This set of MELCs has already been encoded.");
          setSelectedTab(0);
        } else if (e.hasEncoded === false) {
          if (e.melcs.length !== 0) {
            setCompetencies(e.melcs);
            setSelectedTab(1);
          } else if (e.melcs.length === 0) {
            alert("No MELCs found for that selection. Please try again.");
            setSelectedTab(0);
          }
        }
      })
      .catch((err) => {
        // Handle network errors or any other errors here
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (promptResponse) => {
    setLoading(true);
    setError("");

    formService
      .formSubmit({
        ...competencyDetails,
        status: promptResponse.status,
        schoolYear: competencyDetails.schoolYear.toString(),
        data: competencyDetails.data.map((values) => ({
          melcId: values.melcId,
          mps: parseFloat(values.mps),
          sd: parseFloat(values.sd),
        })),
      })
      .then(() => {
        setCompetencyDetails({ ...competencyDetails, data: [] });
        setOpenSuccess(true);
        setSelectedTab(0);
      })
      .catch((err) => {
        setOpenError(true);
        // Handle network errors or any other errors here
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleBack = () => {
    setSelectedTab(0);
    setBack(true);
    setTimeout(() => {
      setBack(false);
    }, 3000);
  };

  const handleGetOfficeDetails = () => {
    setLoading(true);
    setError("");

    formService
      .getOfficeById(auth.officeId)
      .then((e) => {
        setSchoolDetails(e);
      })
      .catch((err) => {
        // Handle network errors or any other errors here
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetOfficeDetails();
  }, []);

  // console.log(competencyDetails);

  return (
    <Box
      sx={{
        display: "block",
        backgroundColor: "#e5bfa1",
        overflowY: "hidden",
        p: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: selectedTab === 0 ? "1000px" : "100%",
          minWidth: "600px",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          boxShadow: "3px 2px 20px 3px rgba(65, 65, 65, 0.6)",
          borderRadius: "10px",
          // position: "relative",
          px: 6,
          py: 2,
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
          }}
        >
          {selectedTab === 1 && (
            <Tooltip title="Go Back" placement="top">
              <IconButton
                onClick={handleBack}
                sx={{
                  position: "absolute",
                  top: 20,
                  left: 20,
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          )}
          <Divider>
            <Typography
              sx={{
                textTransform: "uppercase",
                fontSize: "25px",
              }}
            >
              Encoding Form
            </Typography>
          </Divider>
        </Box>

        <Divider
          sx={{
            backgroundColor: "blue",
            borderRadius: "10px",
            width: "100%",
            py: 0.2,
            my: 2,
          }}
        />

        <Box
          sx={{
            p: 2,
            // ...(selectedTab === 0 ? { my: "2vh" } : { mb: "2vh" }),
            // "@media (max-height: 1080px)": {
            //   ...(selectedTab === 0 ? { my: "5vh" } : { mb: "5vh" }),
            // },
            my: "2vh",
            "@media (max-height: 1080px)": {
              my: "3vh",
            },
            backgroundColor: "#FFF",
            boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.8)",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              overflowY: "auto",
              // height: "40vh",
              width: "100%",
              // "@media (max-height: 1080px)": {
              //   height: "60vh",
              // },
            }}
          >
            {selectedTab === 0 && (
              <CategorizingForm
                fetchCompetencies={handleGetCompetencies}
                onDetailsUpdate={handleCategoriesUpdate}
                competencyDetails={competencyDetails}
                back={back}
                schoolDetails={schoolDetails}
              />
            )}
            {selectedTab === 1 && (
              <EncodingForm
                competencies={competencies}
                competencyDetails={competencyDetails}
                onDetailsUpdate={handleDetailsUpdate}
                submitFunction={handleSubmit}
                setSelectedTab={setSelectedTab}
                error={error} // Not being used
                loading={loading} // Not being used
              />
            )}
          </Box>
          <Typography
            sx={{
              color: "red",
              fontWeight: "bolder",
              fontSize: "15px",
              mb: 4,
            }}
          >
            {error}
          </Typography>
        </Box>
      </Box>
      <SnackbarComponent
        open={openSuccess}
        onClose={handleCloseSuccess}
        severity="success"
        message="Encoding Successful."
      />
      <SnackbarComponent
        open={openError}
        onClose={handleCloseError}
        severity="error"
        message={error}
      />
    </Box>
  );
}
