/* eslint-disable no-plusplus */
/* eslint-disable prefer-spread */
import React, { useEffect, useState } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import EditableTable from "components/smea/Table/EditableTable";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  minHeight: "80vh",
  "@media (min-width: 10px)": {
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    width: "70vw",
  },
};

export default function CRPerGradeLevelModal({
  open,
  handleClose,
  consolidatedData,
  loading,
  error,
}) {
  const [data, setData] = useState([]);

  const handleGetAll = () => {
    const completionRateByGradeLevel = {};

    consolidatedData.forEach((ksData) => {
      Object.values(ksData).forEach((ksDetails) => {
        ksDetails.data.forEach((gradeData) => {
          Object.values(gradeData).forEach((gradeDetails) => {
            const gradeLevel = Object.keys(gradeData)[0];
            if (!completionRateByGradeLevel[gradeLevel]) {
              completionRateByGradeLevel[gradeLevel] = {
                total: 0,
                progress: 0,
              };
            }
            if (gradeDetails.data) {
              gradeDetails.data.forEach((subData) => {
                completionRateByGradeLevel[gradeLevel].total++;
                if (subData.overallAverageMPS !== null) {
                  completionRateByGradeLevel[gradeLevel].progress++;
                }
                // if (subData.componentsData) {
                //   subData.componentsData.forEach((component) => {
                //     completionRateByGradeLevel[gradeLevel].total++;
                //     if (component.overallAverageMPS !== null) {
                //       completionRateByGradeLevel[gradeLevel].progress++;
                //     }
                //   });
                // }
              });
            }
          });
        });
      });
    });

    const completionRateArray = Object.entries(completionRateByGradeLevel).map(
      ([gradeLevel, { total, progress }], index) => ({
        id: index + 1,
        gradeLevel,
        completionRate:
          total === 0 ? "0%" : `${((progress / total) * 100).toFixed(2)}%`,
      })
    );

    setData(completionRateArray);
  };

  function toCamelCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "gradeLevel",
      headerName: "Grade Level",
      width: 200,
      valueGetter: (params) => toCamelCase(params.value),
    },
    {
      field: "completionRate",
      headerName: "Completion Rate",
      width: 120,
    },
  ];

  useEffect(() => {
    handleGetAll();
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              background: "linear-gradient(120deg, #cf1515, #9319a6)",
              borderRadius: "10px",
              width: "70%",
              p: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{ ml: 4, fontSize: 25, fontWeight: "bold", color: "#fff" }}
            >
              COMPLETION RATE PER GRADE LEVEL
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 2,
              mx: 2,
              mb: 2,
            }}
          >
            NOTE: Only <b style={{ marginInline: 4 }}>Submitted</b> data will be
            reflected here
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 2,
              mx: 2,
            }}
          >
            {error}
            <EditableTable
              data={data}
              columns={columns}
              loading={loading}
              height="60vh"
              showSearch
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
