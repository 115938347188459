import React, { useState } from "react";
import "./login.css";
import { AccountCircle } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import accountService from "services/smea/account-service";
import Logo from "../../../assets/images/deped_logo.png";
import bgImg from "../../../assets/images/login_page_bg.png";
import LoginValidation, { initialLog } from "../../../schema/login";
import { useStateContext } from "../../../contexts/ContextProvider";

function LoginPage() {
  const { setAuth } = useStateContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialLog,
    validationSchema: LoginValidation,
    onSubmit: async () => {
      setLoading(true);
      setError("");

      await accountService
        .authenticate(formik?.values)
        .then((res) => {
          if (res.valid) {
            setAuth(res.data);
            localStorage.setItem("authInfo", JSON.stringify(res.data));
            if (res.data.role === "admin") {
              navigate("/");
            } else if (res.data.role === "teacher") {
              navigate("/");
            } else if (res.data.role === "sgod - section head") {
              navigate("/");
            } else if (res.data.role === "sgod - unit head") {
              navigate("/");
            } else if (res.data.role === "sgod - chief") {
              navigate("/");
            } else if (res.data.role === "cid - eps") {
              navigate("/");
            } else if (res.data.role === "cid - chief") {
              navigate("/");
            }
          }
        })
        .catch((err) => {
          let message = "";
          if (err?.response?.status === 404) {
            message = "Invalid Credentials";
          } else if (err?.response?.status === 401) {
            message = err?.response?.data?.error;
          } else {
            message = "Internal Server Error";
          }
          setError(message || err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box className="section">
      <Box className="content-box">
        <Box className="form-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "60%",
              padding: 2,
            }}
          >
            <Box
              className="logo"
              component="img"
              src={Logo}
              alt="Logo"
              width="80%"
              minWidth="100px"
              maxWidth="200px"
              mb={2}
            />
            {/* <Box
              sx={{
                backgroundColor: "#f6e247",
                // background: "linear-gradient(160deg, #86e14d, #64a13e)",
                // background:
                //   "linear-gradient(160deg, #f6e247, #dfd46d, #c49639)",
                // background: "linear-gradient(160deg, #d0d0d0, #a1a1a1)",
                borderRadius: "10px",
                py: 2,
                px: 10,
                width: "100%",
                boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.4)",
              }}
            > */}

            <Typography
              sx={{
                fontFamily: "Poppins",
                // color: "#59395c",
                // color: "#2f2f2f",
                // color: "#f6e247",
                color: "#fff",
                fontWeight: "700",
                fontSize: "25px",
              }}
            >
              DepEd - SDO Imus
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                // color: "#59395c",
                // color: "#2f2f2f",
                // color: "#f6e247",
                color: "#fff",
                fontSize: "18px",
              }}
            >
              Integrated System
            </Typography>
            {/* </Box> */}
          </Box>
          <Box
            variant="form"
            component="form"
            className="login-form"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "red",
                mb: 4,
              }}
            >
              <Typography
                sx={{
                  color: "lightgray",
                  fontWeight: "bolder",
                  fontSize: "15px",
                }}
              >
                {error}
              </Typography>
            </Box>

            <TextField
              id="username"
              placeholder="Username"
              variant="outlined"
              size="small"
              disabled={loading}
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBLur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-notchedOutline": {
                  // borderColor: "#59395c",
                  // borderColor: "#2f2f2f",
                  // borderColor: "#f6e247",
                  borderColor: "#fff",
                  transition: "border-color 0.2s ease-in-out",
                },
              }}
              InputProps={{
                style: {
                  // color: "#59395c",
                  // color: "#2f2f2f",
                  // color: "#f6e247",
                  color: "#fff",
                  fontWeight: "bold",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle
                      sx={{
                        // color: "#59395c",
                        // color: "#2f2f2f",
                        // color: "#f6e247",
                        color: "#fff",
                        borderRadius: "2px 0px 0px 2px",
                        height: "30px",
                        width: "30px",
                        mr: "10px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              size="small"
              disabled={loading}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBLur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  // borderColor: "#59395c",
                  // borderColor: "#2f2f2f",
                  // borderColor: "#f6e247",
                  borderColor: "#fff",
                  transition: "border-color 0.2s ease-in-out",
                },
                "& .MuiInputLabel-root": {
                  // color: "#59395c",
                  // color: "#2f2f2f",
                  // color: "#f6e247",
                  color: "#fff",
                },
              }}
              InputProps={{
                style: {
                  // color: "#59395c",
                  // color: "#2f2f2f",
                  // color: "#f6e247",
                  color: "#fff",
                  fontWeight: "bold",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon
                      sx={{
                        // color: "#59395c",
                        // color: "#2f2f2f",
                        // color: "#f6e247",
                        color: "#fff",
                        borderRadius: "2px 0px 0px 2px",
                        height: "30px",
                        width: "30px",
                        mr: "10px",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onKeyPress={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityIcon
                        size={18}
                        sx={{
                          // color: "#59395c",
                          // color: "#2f2f2f",
                          // color: "#f6e247",
                          color: "#fff",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        size={18}
                        sx={{
                          // color: "#59395c",
                          // color: "#2f2f2f",
                          // color: "#f6e247",
                          color: "#fff",
                        }}
                      />
                    )}
                  </IconButton>
                ),
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
                mb: 4,
                pt: 1,
                mx: "auto",
              }}
            >
              <Typography
                onClick={() => navigate("/not-found")}
                sx={{
                  // fontFamily: "'-apple-system', sans-serif",
                  // color: "#59395c",
                  // color: "#2f2f2f",
                  // color: "#f6e247",
                  color: "#fff",
                  fontSize: "14px",
                  cursor: "pointer",
                  "&:hover": {
                    color: "blue",
                  },
                }}
              >
                Forgot Password?
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Button
                  id="login-btn"
                  type="submit"
                  variant="contained"
                  sx={{
                    fontFamily: "Poppins",
                    // backgroundColor: "#59395c",
                    // backgroundColor: "#2f2f2f",
                    // backgroundColor: "#f6e247",
                    backgroundColor: "lightgray",
                    borderRadius: "15px",
                    color: "black",
                    // color: "#fff",
                    fontSize: "15px",
                    padding: "8px 5px",
                    margintop: "15px",
                    width: "40%",
                    minWidth: "150px",
                    height: "40px",
                    "&:hover": {
                      // backgroundColor: "#59395c",
                      // backgroundColor: "lightgray",
                      backgroundColor: "#2f2f2f",
                      // color: "#59395c",
                      color: "#fff",
                      fontWeight: "bolder",
                    },
                  }}
                >
                  Log in
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              left: 20,
              bottom: 5,
              backgroundColor: "#1b1b1b",
              // backgroundColor: "transparent",
              zIndex: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                // color: "#59395c",
                // color: "#2f2f2f",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              © DepEd Imus Division | 2024.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="img-box">
        <img src={bgImg} alt="bgImg" />
      </Box>
    </Box>
  );
}

export default LoginPage;
