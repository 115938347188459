import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BasicTable from "components/i-abide/Table/BasicTable";
import EditableTable from "components/smea/Table/EditableTable";
import { useEffect, useState } from "react";
import formService from "services/i-abide/form-service";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.4)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  height: "80vh",
  overflow: "auto",
  scrollbarWidth: "thin",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0.5rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#53FDFD",
  },
  "@media (min-width: 10px)": {
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    width: "65vw",
  },
};

export default function SummaryModal({ open, handleClose }) {
  const [locationName, setLocationName] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [tallyData, setTallyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const columns = [
    { field: "north", headerName: "NORTH", width: 120 },
    { field: "south", headerName: "SOUTH", width: 120 },
    { field: "east", headerName: "EAST", width: 150 },
    { field: "west", headerName: "WEST", width: 100 },
    { field: "JHS", headerName: "JHS", width: 150 },
    { field: "SHS", headerName: "SHS", width: 150 },
  ];

  const columnsByLocation = [
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: () => (
        <Button
          // onClick={() => setOpenSummary(true)}
          sx={{
            backgroundColor: "#1976d2",
            color: "#fff",
            // fontSize: "14px",
            fontWeight: "bold",
            padding: "3px 5px",
            borderRadius: "2px",
            mr: 2,
            "&:hover": {
              backgroundColor: "lightgray",
              color: "#2f2f2f",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <VisibilityIcon />
        </Button>
      ),
    },
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "requirements",
      headerName: "Requirements",

      width: 150,
    },
    {
      field: "iAbideFormId",
      headerName: "Form ID",

      width: 150,
    },
    {
      field: "fileName",
      headerName: "File Name",

      width: 150,
    },
    {
      field: "submittedBy",
      headerName: "Submitted By",
      width: 200,
    },
    {
      field: "office",
      headerName: "Office Name",
      width: 300,
    },
    {
      field: "location",
      headerName: "Location",
      width: 150,
    },
  ];

  function toCamelCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  const handleGetAll = () => {
    setLoading(true);

    formService
      .getSummaryData(locationName)
      .then((response) => {
        setSummaryData(response.data);
        setTallyData(response.status);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, [locationName]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            backgroundColor: "#007a9c",
            borderRadius: "5px",
            width: "30%",
            p: 1,
            mb: 5,
            mx: "auto",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 16,
              // fontWeight: "bold",
              fontFamily: "Poppins",
              color: "#fFfFff",
            }}
          >
            OVERALL SUMMARY
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            my: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Poppins",
              color: "solid black",
              mr: 2,
              py: 1.5,
            }}
          >
            Filter by location:
          </Typography>

          <TextField
            placeholder="Location Name" // Placeholder added here
            variant="outlined"
            size="small"
            value={locationName}
            onChange={(event) => setLocationName(event.target.value)}
            sx={{ width: "20%" }}
            select
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    width: 250,
                  },
                },
              },
            }}
          >
            {columns.map((column) => (
              <MenuItem key={column.field} value={toCamelCase(column.field)}>
                {toCamelCase(column.field)}
              </MenuItem>
            ))}
          </TextField>

          {locationName !== null && (
            <IconButton
              onClick={() => {
                setLocationName(null);
              }}
            >
              <CancelIcon />
            </IconButton>
          )}
        </Box>
        <Box sx={{ my: 2 }}>
          <BasicTable rows={tallyData} columns={columns} />
        </Box>

        <Box sx={{ my: 2 }}>
          <Divider
            sx={{ mt: 2, mb: 3, fontWeight: "bold", fontFamily: "Poppins" }}
          >
            SUMMARY BY LOCATION
          </Divider>
          {error}
          <EditableTable
            data={summaryData}
            columns={columnsByLocation}
            loadingState={loading}
            height="60vh"
          />
        </Box>
      </Box>
    </Modal>
  );
}
