import React, { createContext, useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

const StateContext = createContext();

export function ContextProvider({ children }) {
  const storedAuth = localStorage.getItem("authInfo");
  const [auth, setAuth] = useState(JSON.parse(storedAuth) || null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [schoolsCR, setSchoolsCR] = useState([]);
  const [schoolsCRCount, setSchoolsCRCount] = useState(0);
  const [subjectCRCount, setSubjectCRCount] = useState(0);
  const [subjectData, setSubjectData] = useState([]);
  const [gradeLevelCRCount, setGradeLevelCRCount] = useState(0);
  const [gradeLevelData, setGradeLevelData] = useState([]);

  const contextValue = useMemo(
    () => ({
      auth,
      setAuth,
      sessionExpired,
      setSessionExpired,
      schoolsCR,
      setSchoolsCR,
      schoolsCRCount,
      setSchoolsCRCount,
      subjectData,
      setSubjectData,
      subjectCRCount,
      setSubjectCRCount,
      gradeLevelData,
      setGradeLevelData,
      gradeLevelCRCount,
      setGradeLevelCRCount,
    }),
    [
      auth,
      setAuth,
      sessionExpired,
      setSessionExpired,
      schoolsCR,
      setSchoolsCR,
      schoolsCRCount,
      setSchoolsCRCount,
      subjectCRCount,
      setSubjectCRCount,
      subjectData,
      setSubjectData,
      gradeLevelCRCount,
      setGradeLevelCRCount,
      gradeLevelData,
      setGradeLevelData,
    ]
  );

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
}

export const useStateContext = () => useContext(StateContext);

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
