import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import DatePicker from "react-datepicker";
import SelectGrade from "components/smea/Textfields/SelectGrade";
import SelectSubject from "components/smea/Textfields/SelectSubject";
import SelectQuarter from "components/smea/Textfields/SelectQuarter";
// eslint-disable-next-line import/no-extraneous-dependencies
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import SelectComponent from "components/smea/Textfields/SelectComponents";
import formService from "services/smea/form-service";
import { useStateContext } from "contexts/ContextProvider";

export default function CategorizingForm({
  fetchCompetencies,
  onDetailsUpdate,
  competencyDetails,
  back,
  schoolDetails,
}) {
  const { auth } = useStateContext();

  const { schoolYear } = competencyDetails;
  let start;
  let end;
  if (schoolYear) {
    [start, end] = schoolYear.split("-");
  }

  const [subject, setSubject] = useState(competencyDetails.subjectId || "");
  const [component, setComponent] = useState(
    competencyDetails.componentId || ""
  );
  const [schoolYearStart, setSchoolYearStart] = useState(start || null);
  const [schoolYearEnd, setSchoolYearEnd] = useState(end || null);
  const [grade, setGrade] = useState(
    back
      ? competencyDetails.gradeLevelId || ""
      : competencyDetails.gradeLevelId + 1 || ""
  );

  const [gradeLevels, setGradeLevels] = useState([]);

  const [quarter, setQuarter] = useState(competencyDetails.quarterId || null);
  const [disable, setDisable] = useState(true);

  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");

  const handleGenerateCompetencies = () => {
    if (component) {
      fetchCompetencies({ subject, grade, quarter, component });
    } else {
      fetchCompetencies({ subject, grade, quarter });
    }
  };

  useEffect(() => {
    if (subject && quarter) {
      setLoading(true);
      setError("");

      formService
        .getGradeLevelsDropdown({
          subject,
          component,
          schoolId: auth?.officeId,
          quarter,
        })
        .then((e) => {
          setGradeLevels(e);
          const isGradeIncluded = e.some(
            (gradeLevel) => gradeLevel.levelGrade === grade
          );
          if (!isGradeIncluded) {
            setGrade(null);
          }
        })
        .catch((err) => {
          // Handle network errors or any other errors here
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [subject, component, grade, quarter]);

  useEffect(() => {
    const formattedSchoolYear = `${schoolYearStart}-${schoolYearEnd}`;

    onDetailsUpdate({
      schoolYear: formattedSchoolYear,
      subject,
      component,
      grade,
      quarter,
    });
  }, [schoolYearStart, schoolYearEnd, subject, component, grade, quarter]);

  useEffect(() => {
    if (subject === 8) {
      setDisable(false);
    } else if (subject === 9) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [subject]);

  useEffect(() => {
    if (schoolYearStart > schoolYearEnd) {
      setSchoolYearStart();
      setSchoolYearEnd();
      setWarning(`Year Start shouldn't be greater than Year End`);
      setTimeout(() => {
        setWarning("");
      }, 3000);
    }
  }, [schoolYearStart, schoolYearEnd]);

  // console.log(grade);

  return (
    <Box pt={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>
            NOTE: Fields marked with an asterisk (*) are required
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ zIndex: 1 }}>
          <FormControl component="fieldset">
            <Typography sx={{ color: "#2f2f2f" }}>School Year *</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 1,
                ml: 2,
              }}
            >
              <DatePicker
                className="datepicker"
                placeholderText="Start Year"
                selected={
                  schoolYearStart ? new Date(schoolYearStart, 0, 1) : null
                }
                onChange={(date) => {
                  setSchoolYearStart(date?.getFullYear());
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  setSchoolYearEnd(date?.getFullYear() + 1);
                }}
                dateFormat="yyyy"
                showYearPicker
                scrollableYearDropdown
                yearDropdownScrollOffset={9}
              />

              <Typography sx={{ mx: 2, fontWeight: "bold", color: "gray" }}>
                -
              </Typography>
              <Box
                sx={{
                  borderRadius: "4px",
                  border: "solid 1px #b6b6b6",
                  width: "230px",
                  // height: "40px",
                  color: schoolYearEnd ? "black" : "#757575",
                  py: "8px",
                  px: "12px",
                }}
              >
                <Typography>{schoolYearEnd || "End Year"}</Typography>
              </Box>
            </Box>
          </FormControl>
          <Typography
            sx={{ fontSize: "15px", color: "gray", fontStyle: "italic" }}
          >
            {warning}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: "#2f2f2f", mb: 1 }}>Grade *</Typography>
          <SelectGrade
            placeholder="Select Grade"
            // label="Grade"
            name="grade"
            // disabled={loading}
            value={grade}
            onChange={(fieldName, selectedValue) => {
              setGrade(selectedValue);
            }}
            gradeLevels={gradeLevels}
            schoolDetails={schoolDetails}
            sx={{
              width: "100%",
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black !important",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: "#2f2f2f", mb: 1 }}>Quarter *</Typography>
          <SelectQuarter
            placeholder="Select Quarter"
            // label="Grading Period"
            name="quarter"
            // disabled={loading}
            value={quarter}
            onChange={(fieldName, selectedValue) => {
              setQuarter(selectedValue);
            }}
            // disabled
            sx={{
              width: "100%",
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black !important",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: "#2f2f2f", mb: 1 }}>Subject *</Typography>
          <SelectSubject
            placeholder="Select a Subject"
            // label="Select a Subject"
            name="subject"
            // disabled={loading}
            value={subject}
            onChange={(fieldName, selectedValue) => {
              setSubject(selectedValue);
              setComponent("");
            }}
            schoolDetails={schoolDetails}
            sx={{
              width: "100%",
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black !important",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: "#2f2f2f", mb: 1 }}>
            Component {subject === 8 || subject === 9 ? "*" : ""}
          </Typography>
          <SelectComponent
            placeholder="Select a Component"
            name="component"
            // disabled={loading}
            value={component}
            onChange={(fieldName, selectedValue) => {
              setComponent(selectedValue);
            }}
            disabled={disable}
            subId={subject}
            gradeId={grade}
            sx={{
              width: "100%",
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black !important",
                },
              },
            }}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <Button
          id="search"
          onClick={() => handleGenerateCompetencies()}
          variant="contained"
          disabled={
            !schoolYearStart ||
            !schoolYearEnd ||
            !subject ||
            (!component && !disable) ||
            loading ||
            !grade ||
            !quarter
          }
          sx={{
            backgroundColor: "#2031C9",
            color: "#fff",
            fontSize: "15px",
            padding: "15px 5px",
            margintop: "15px",
            width: "120px",
            height: "40px",
            mr: 6,
            "&:hover": {
              backgroundColor: "#2F2F2F",
              fontWeight: "bolder",
            },
          }}
        >
          Encode
        </Button>
      </Box>
      {error}
    </Box>
  );
}
