import { useStateContext } from "contexts/ContextProvider";
import accountService from "../../services/smea/account-service";

const useRefresh = () => {
  const { setAuth } = useStateContext();

  const refresh = async () => {
    const response = await accountService.refresh();

    setAuth((prev) => {
      if (prev) {
        return { ...prev, accessToken: response.accessToken };
      }
      return response;
    });
    return response.accessToken;
  };
  return refresh;
};

export default useRefresh;
