import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import formService from "services/smea/form-service";
import SelectOfficeType from "components/smea/Textfields/SelectOfficeType";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function UpdateOfficeModal({
  open,
  handleClose,
  data,
  updateTableFunction,
}) {
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      officeName: data?.officeName || "",
      officeTypeId: data?.officeTypeId || "",
      locationName: data?.locationName || "",
    },

    validationSchema: object().shape({
      officeName: string().required("Required"),
      officeTypeId: string().required("Required"),
      locationName: string().required("Required"),
    }),
    onSubmit: () => {
      setLoading(true);
      setError("");

      let values;

      if (formik.values.locationName) {
        values = formik.values;
      } else {
        const { locationName, ...otherValues } = formik.values;
        values = otherValues;
      }

      formService
        .updateOffice(data.id, values)
        .then(() => {
          alert("Office Updated");
          updateTableFunction();
          handleClose();
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data) {
      const initialValues = {
        officeName: data?.officeName || "",
        officeTypeId: data?.officeTypeId || "",
        locationName: data?.locationName || "",
      };
      formik.setValues(initialValues);
    }
  }, [data]);

  useEffect(() => {
    const { locationName, ...otherValues } = formik.values;
    const areAllValuesFilled = Object.values(otherValues).every(
      (value) => !!value
    );

    setDisabled(areAllValuesFilled);
  }, [formik.values]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box
        variant="form"
        component="form"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        sx={style}
      >
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundColor: "blue",
              borderRadius: "10px",
              width: "70%",
              p: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{ ml: 4, fontSize: 25, fontWeight: "bold", color: "#fff" }}
            >
              UPDATE OFFICE
            </Typography>
          </Box>
          {error}
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 4,
              mx: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="officeName"
                  label={`Office Name ${formik?.values?.officeName ? "" : "*"}`}
                  size="small"
                  disabled={loading}
                  value={formik?.values?.officeName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBLur}
                  error={
                    formik?.touched?.officeName &&
                    Boolean(formik?.errors?.officeName)
                  }
                  helperText={
                    formik?.touched?.officeName && formik?.errors?.officeName
                  }
                  variant="outlined"
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <SelectOfficeType
                  label={`Office Type ${
                    formik?.values?.officeTypeId ? "" : "*"
                  }`}
                  // label="Subject"
                  name="subject"
                  width="100%"
                  // disabled={loading}
                  value={formik?.values?.officeTypeId}
                  onChange={(fieldName, selectedValue) => {
                    formik?.setFieldValue("officeTypeId", selectedValue);
                  }}
                  onBlur={formik?.handleBLur}
                  error={
                    formik?.touched?.officeTypeId &&
                    Boolean(formik?.errors?.officeTypeId)
                  }
                  helperText={
                    formik?.touched?.officeTypeId &&
                    formik?.errors?.officeTypeId
                  }
                  sx={{
                    width: "100%",
                    pr: 5,
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="locationName"
                  label={`Location ${formik.values.locationName ? "" : "*"}`}
                  size="small"
                  disabled={loading}
                  value={formik.values.locationName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.locationName &&
                    Boolean(formik.errors.locationName)
                  }
                  helperText={
                    formik.touched.locationName && formik.errors.locationName
                  }
                  variant="outlined"
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            p: 2,
            zIndex: 10,
          }}
        >
          <Button
            disabled={!disabled}
            type="submit"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: disabled ? "blue" : "lightgray",
              color: "#fff",
              py: 1,
              width: "10vw",
              minWidth: "100px",
              "&:hover": {
                border: "solid 1px gray",
                backgroundColor: "lightgray",
                color: "#2b2b2b",
                fontWeight: "bold",
              },
            }}
          >
            <SaveIcon
              sx={{
                mr: 1,
                color: "#fff",
              }}
            />
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
