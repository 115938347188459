import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function BasicTable({ rows, columns }) {
  return (
    <TableContainer component={Paper} sx={{ height: "120px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns?.map((column) => (
              <TableCell
                align="center"
                bgColor="#0000ff"
                sx={{ fontWeight: "bold", color: "#fff", width: 100 }}
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="center">{rows?.north}</TableCell>
            <TableCell align="center">{rows?.south}</TableCell>
            <TableCell align="center">{rows?.east}</TableCell>
            <TableCell align="center">{rows?.west}</TableCell>
            <TableCell align="center">{rows?.JHS}</TableCell>
            <TableCell align="center">{rows?.SHS}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
