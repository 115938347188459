import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import EditableTable from "components/smea/Table/EditableTable";
import dayjs from "dayjs";
import UploadingModal from "modals/i-abide/UploadingModal";
import { useStateContext } from "contexts/ContextProvider";
// import { GridActionsCellItem } from "@mui/x-data-grid";
// import formService from "services/smea/form-service";
// import PromptModal from "modals/smea/miscellaneous/PromptModal";

export default function IABIDETable({
  data,
  loadingState,
  // updateTableFunction,
}) {
  const { auth } = useStateContext();
  // const [selectedMELC, setSelectedMELC] = useState();
  // const [rowToDelete, setRowToDelete] = useState();
  const [uploadingData, setUploadingData] = useState();

  const [open, setOpen] = useState(false);
  // const [promptResponse, setPromptResponse] = useState(null);
  // const [submit, setSubmit] = useState(false);
  // const [submitKind, setSubmitKind] = useState("");
  // const [promptDesc, setPromptDesc] = useState("");

  // const [loading, setLoading] = useState(loadingState);

  // useEffect(() => {
  //   if (rowToDelete) {
  //     setPromptDesc("Are you sure you want to delete this data?");
  //     setSubmitKind("delete");
  //     setOpen(true);
  //   }
  // }, [rowToDelete]);

  // useEffect(() => {
  //   if (!open && submit && promptResponse) {
  //     setLoading(true);

  //     formService
  //       .deleteMELC(rowToDelete ? rowToDelete[0]?.id : "")
  //       .then(() => {
  //         alert("MELC deleted successfully");
  //         setRowToDelete(null);
  //         setSubmit(false);
  //         updateTableFunction();
  //       })
  //       .catch((err) => {
  //         alert(err?.message);
  //         setRowToDelete(null);
  //         setSubmit(false);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [submit, promptResponse]);

  const handleButtonClick = (formData) => {
    setOpen(true);
    setUploadingData(formData);
  };

  let filteredColumns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#1976d2",
            fontFamily: "Poppins",
          }}
          variant="contained"
          // color="#1976d2"
          onClick={() => handleButtonClick(params.row)}
        >
          UPLOAD
        </Button>
      ),
    },
    { field: "id", headerName: "ID", width: 70 },
    { field: "requirements", headerName: "Requirements", width: 250 },
    {
      field: "activity_Program",
      headerName: "Activity/Program/Project",
      width: 200,
    },
    {
      field: "link_to_memo",
      headerName: "Link to Memo",
      width: 130,
    },
    {
      field: "title_of_memo",
      headerName: "Title of Memo",
      width: 130,
    },
    {
      field: "posting_date",
      headerName: "Posting Date",
      width: 130,
      valueGetter: (params) => dayjs(params?.value).format("MM-DD-YYYY"),
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 130,
      valueGetter: (params) => dayjs(params?.value).format("MM-DD-YYYY"),
    },
    {
      field: "due_time",
      headerName: "Due Time",
      width: 130,
    },
    {
      field: "posted_by",
      headerName: "Posted By",
      width: 130,
    },
    {
      field: "role",
      headerName: "Role",
      width: 130,
    },
    {
      field: "folderName",
      headerName: "Folder Name",
      width: 130,
    },
    {
      field: "subDir",
      headerName: "Subdirectory",
      width: 130,
    },
  ];

  if (auth.role !== "teacher") {
    filteredColumns = filteredColumns.filter((obj) => obj.field !== "actions");
  }

  return (
    <Box>
      <UploadingModal
        open={open}
        handleClose={() => setOpen(false)}
        data={uploadingData}
      />

      {/*
      <PromptModal
        open={open}
        handleClose={() => setOpen(false)}
        setPromptResponse={setPromptResponse}
        setSubmit={setSubmit}
        submitKind={submitKind}
        promptDesc={promptDesc}
  /> 

  */}

      <EditableTable
        data={data}
        columns={filteredColumns}
        loadingState={loadingState}
        // rowToDelete={setRowToDelete}
        height="60vh"
        fontFamily="Poppins"
      />
    </Box>
  );
}
