/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "contexts/ContextProvider";
import "./landing.css";
import MainTopBar from "components/smea/Topbar/MainTopBar";
import bgImage from "../../assets/images/klase.jpg";

export default function Landing() {
  const { auth } = useStateContext();
  const navigate = useNavigate();
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const GoToAdmin = () => {
    if (auth.role === "teacher") {
      navigate("school-dashboard");
    } else {
      navigate("dashboard");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        overflow: "auto",
        // overflowX: "hidden",
        scrollbarWidth: "thin",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          width: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#53FDFD",
        },
      }}
    >
      <MainTopBar from="landing" />
      <Box
        sx={{
          flex: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundAttachment: "fixed",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
              height: "100%",
              // background:
              //   "linear-gradient(40deg, rgba(66, 201, 116, 0.8), rgba(59, 152, 184, 0.9), rgba(26, 214, 164, 0.9))",
              background: "rgba(255, 255, 255, 0.6)",
              boxShadow: "3px 2px 20px 3px rgba(65, 65, 65, 0.7)",
              clipPath: "polygon(5% 0, 100% 0, 95% 100%, 0 100%)",
              // clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);
              px: 8,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  letterSpacing: 1,
                  fontWeight: "500",
                  fontSize: "60px",
                  color: "#323232",
                  "@media (max-width: 1080px)": {
                    fontSize: "40px",
                  },

                  "@media (max-width: 900px)": {
                    fontSize: "30px",
                  },
                  // textShadow:
                  //   "2px 2px 2px rgba(0, 0, 0, 1), -2px -2px 2px rgba(25, 25, 25, 0.8)",
                }}
              >
                Welcome!
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  // fontWeight: "300",
                  fontSize: "40px",
                  color: "#323232",
                  mb: 4,
                  "@media (max-width: 1080px)": {
                    fontSize: "30px",
                    mb: 2,
                  },

                  "@media (max-width: 900px)": {
                    fontSize: "20px",
                    mb: 2,
                  },
                  // textShadow:
                  //   "2px 2px 2px rgba(0, 0, 0, 1), -2px -2px 2px rgba(0, 0, 0, 1)",
                }}
              >
                {auth.officeName || "Administrator"}
              </Typography>
              <Divider
                className="colorchange"
                sx={{
                  // backgroundColor: "blue",
                  width: "50%",
                  py: 0.5,
                  borderRadius: "10px",
                  mb: 4,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  navigate("/i-abide");
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "linear-gradient(120deg, #32b270, #0f8548)",
                  padding: "8px 15px",
                  width: "12vw",
                  minWidth: "100px",
                  borderRadius: "15px",
                  mr: 8,
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  I-ABIDE
                </Typography>
              </Button>
              <Button
                onClick={GoToAdmin}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "linear-gradient(120deg, #2a5973, #446e87)",
                  padding: "8px 15px",
                  width: "12vw",
                  minWidth: "100px",
                  borderRadius: "15px",
                  zIndex: 1000,
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  SMEA-LOADGAT
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
