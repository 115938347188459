import axios from "axios";

// const BASE_URL = "http://localhost:9000";
const BASE_URL = "https://smea.depedimuscity.com:8020";
// const BASE_URL = "http://192.168.10.11:8020";

function formSubmit(form) {
  return axios
    .post(`${BASE_URL}/I-Abide/createFolderWithSubdirectory`, form)
    .then((res) => res);
}

function getIABIDEData() {
  return axios.get(`${BASE_URL}/I-Abide/getAll`).then((res) => res.data);
}

function getSummaryData(locationName) {
  return axios
    .get(`${BASE_URL}/I-Abide/filterSubmissions`, { params: { locationName } })
    .then((res) => res.data);
}

function submitRequirements(data) {
  return axios
    .post(`${BASE_URL}/I-Abide/schoolUpload`, data)
    .then((res) => res);
}

export default {
  submitRequirements,
  formSubmit,
  getIABIDEData,
  getSummaryData,
};
