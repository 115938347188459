import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import formService from "services/i-abide/form-service";
import dayjs from "dayjs";
import { useStateContext } from "contexts/ContextProvider";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.4)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function UploadingModal({
  open,
  handleClose,
  data,
  // updateTableFunction,
}) {
  const { auth } = useStateContext();
  const [fileName, setFileName] = useState("");
  const [fileBlob, setFileBlob] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: { submittedBy: "" },

    validationSchema: object().shape({
      submittedBy: string().required("Required"),
    }),

    onSubmit: (values) => {
      setLoading(true);
      setError("");

      const formikValues = {
        ...values,
        officeRelated: auth.uid,
        iAbideFormId: data.id,
        file: fileBlob,
      };

      // Create a new FormData object
      const formData = new FormData();

      // Append all form values to the FormData object
      Object.entries(formikValues).forEach(([key, value]) => {
        // If the value is a file Blob, append it directly to the FormData object
        if (key === "file" && value instanceof Blob) {
          formData.append(key, value, fileName); // You can specify a filename if needed
        } else {
          formData.append(key, value);
        }
      });

      formService
        .submitRequirements(formData)
        .then(() => {
          alert("Upload Successful");
          formik.resetForm();
          // updateTableFunction();
          setFileName("");
        })
        .catch((err) => {
          console.log(err);
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (fileName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [fileName]);

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    // eslint-disable-next-line prefer-destructuring
    const files = fileInput.files;

    // Check if files were selected
    if (files.length > 0) {
      const selectedFile = files[0];
      setFileName(selectedFile.name);

      // Read the file content as a Blob
      const reader = new FileReader();
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: selectedFile.type });
        setFileBlob(blob);
      };
      reader.readAsArrayBuffer(selectedFile);
    }

    fileInput.value = "";
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box
        variant="form"
        component="form"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        sx={style}
      >
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundColor: "#007a9c",
              borderRadius: "10px",
              width: "91%",
              ml: "3.5%",
              p: 1,
              mb: 1,
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: 18,
                fontWeight: "bold",
                fontFamily: "Poppins",
                color: "#fff",
              }}
            >
              UPLOAD FORM
            </Typography>
          </Box>
          {error}
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 4,
              mx: 4,
            }}
          >
            <Divider
              textAlign="left"
              sx={{ mb: 3, fontWeight: "bold", fontFamily: "Poppins" }}
            >
              FORM DETAILS
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  fontSize={14}
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Requirements
                </Typography>
                <Typography fontSize={14} fontFamily="Poppins">
                  {data?.requirements}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontSize={14}
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Activity / Program
                </Typography>
                <Typography fontSize={14} fontFamily="Poppins">
                  {data?.activity_Program}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  fontSize={14}
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Link to Memo
                </Typography>
                <Typography fontSize={14} fontFamily="Poppins">
                  <a
                    href={data?.link_to_memo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data?.link_to_memo}
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  fontSize={14}
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Title of Memo
                </Typography>
                <Typography fontSize={14} fontFamily="Poppins">
                  {data?.title_of_memo}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontSize={14}
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Posted By
                </Typography>
                <Typography fontSize={14} fontFamily="Poppins">
                  {data?.posted_by}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  fontSize={14}
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Date Posted
                </Typography>
                <Typography fontSize={14} fontFamily="Poppins">
                  {dayjs(data?.posting_date).format("MM-DD-YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontSize={14}
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Due Date
                </Typography>
                <Typography fontSize={14} fontFamily="Poppins">
                  {dayjs(data?.due_date).format("MM-DD-YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontSize={14}
                  fontFamily="Poppins"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Due Time
                </Typography>
                <Typography fontSize={16} fontFamily="Poppins">
                  {data?.due_time}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  textAlign="left"
                  sx={{
                    mt: 2,
                    mb: 1,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  UPLOAD FILE
                </Divider>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="submittedBy"
                  label="Submitted By"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik?.values?.submittedBy}
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBLur}
                  error={
                    formik?.touched?.submittedBy &&
                    Boolean(formik?.errors?.submittedBy)
                  }
                  helperText={
                    formik?.touched?.submittedBy && formik?.errors?.submittedBy
                  }
                  sx={{ pr: 5, fontFamily: "Poppins" }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="designation"
                  label="Designation"
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik?.values?.designation}
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBLur}
                  error={
                    formik?.touched?.designation &&
                    Boolean(formik?.errors?.designation)
                  }
                  helperText={
                    formik?.touched?.designation && formik?.errors?.designation
                  }
                  sx={{ pr: 5, fontFamily: "Poppins" }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <Box
                  htmlFor="fileUpload"
                  mx={2}
                  sx={{
                    // textAlign: "center",
                    ml: 0.3,
                    mt: 1,
                    padding: "8px 0",
                    backgroundColor: "#000000",
                    // borderRadius: "",
                    cursor: "pointer",
                    width: "30%",
                  }}
                >
                  <Typography
                    variant="label"
                    component="label"
                    htmlFor="fileUpload"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      fontSize: "small",
                      color: "#fff",
                      fontFamily: "Poppins",
                    }}
                  >
                    <UploadIcon sx={{ mr: 1 }} />
                    Upload File
                  </Typography>
                  <input
                    id="fileUpload"
                    type="file"
                    name="file_upload"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                </Box>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    disabled={!loading}
                    sx={{
                      mt: -5,
                      ml: 20.5,
                      width: "70.5%",
                      fontFamily: "Poppins",
                    }}
                    value={fileName}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                zIndex: 10,
              }}
            >
              <Button
                disabled={disabled}
                type="submit"
                sx={{
                  fontFamily: "Poppins",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: !disabled ? "#007a9c" : "#c3c3c3",
                  color: "#fff",
                  py: 1,
                  width: "10vw",
                  minWidth: "100px",
                  "&:hover": {
                    border: "solid 1px gray",
                    backgroundColor: "lightgray",
                    color: "#2b2b2b",
                    fontWeight: "bold",
                  },
                }}
              >
                <AddIcon
                  sx={{
                    mr: 1,
                    color: "#fff",
                  }}
                />
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
