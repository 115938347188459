/* eslint-disable no-plusplus */
/* eslint-disable prefer-spread */
import React, { useEffect, useState } from "react";
import { Box, IconButton, Modal, Tab, Tabs, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import LMCs from "./LMCs";
import LMCComments from "./LMCsComments";
import CommentForm from "./CommentForm";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  minHeight: "80vh",
  "@media (min-width: 10px)": {
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    width: "70vw",
  },
};

export default function LeastMasteredCompetencyModal({
  open,
  handleClose,
  consolidatedData,
  LMCWithCommentsData,
  loadingState,
  error,
}) {
  const [data, setData] = useState(0);

  const [selectedTab, setSelectedTab] = useState(0);
  const [dataToUpdate, setDataToUpdate] = useState([]);

  const handleGetAll = () => {
    let idByCount = 0;
    const flattenedData = consolidatedData.flatMap((ks) =>
      // eslint-disable-next-line no-unused-vars
      Object.entries(ks).map(([ksName, ksData]) =>
        ksData.data.map((gradeData) =>
          Object.entries(gradeData).map(([gradeName, gradeInfo]) =>
            gradeInfo.data.map((subjectInfo) => {
              if (subjectInfo.componentsData) {
                return subjectInfo.componentsData.map((component) => {
                  idByCount++;
                  return {
                    id: `${idByCount}`,
                    gradeLevel: gradeName,
                    subject: subjectInfo.subject,
                    component: component.subject,
                    mps: component.mps,
                    melcs: component.least_mastered,
                  };
                });
              }
              idByCount++;
              return {
                id: `${idByCount}`,
                gradeLevel: gradeName,
                subject: subjectInfo.subject,
                component: "",
                mps: subjectInfo.mps,
                melcs: subjectInfo.least_mastered,
              };
            })
          )
        )
      )
    );

    // Flatten the nested arrays
    const dataObject = [].concat.apply(
      [],
      [].concat.apply([], [].concat.apply([], flattenedData))
    );

    setData(dataObject.flat());
  };

  useEffect(() => {
    handleGetAll();
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              background: "linear-gradient(120deg, #27bf13, #207a14)",
              borderRadius: "10px",
              width: "70%",
              p: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{ ml: 4, fontSize: 25, fontWeight: "bold", color: "#fff" }}
            >
              LEAST MASTERED COMPETENCIES
            </Typography>
          </Box>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="secondary"
            sx={{
              mb: 2,
            }}
          >
            <Tab
              onClick={() => setSelectedTab(0)}
              label="Least Mastered Competencies"
              style={{
                margin: "0 20px 0 20px",
                fontFamily: "Poppins, sans-serif",
                fontSize: "small",
                fontWeight: "900",
                color: selectedTab === 0 ? "blue" : "#2e3a3f",
              }}
            />
            <Tab
              onClick={() => setSelectedTab(1)}
              label="Commented LMCs"
              style={{
                margin: "0 20px 0 20px",
                fontFamily: "Poppins, sans-serif",
                fontSize: "small",
                fontWeight: "900",
                color: selectedTab === 1 ? "blue" : "#2e3a3f",
              }}
            />
            <Tab
              onClick={() => setSelectedTab(2)}
              label="Add Comments"
              style={{
                margin: "0 20px 0 20px",
                fontFamily: "Poppins, sans-serif",
                fontSize: "small",
                fontWeight: "900",
                color: selectedTab === 2 ? "blue" : "#2e3a3f",
              }}
            />
          </Tabs>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 2,
              mx: 2,
              mb: 2,
            }}
          >
            NOTE: Only <b style={{ marginInline: 4 }}>Submitted</b> data will be
            reflected here
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 2,
              mx: 2,
            }}
          >
            {error}
            {selectedTab === 0 && (
              <Box sx={{ width: "100%", position: "relative" }}>
                <LMCs
                  data={data}
                  loadingState={loadingState}
                  setDataToUpdate={setDataToUpdate}
                />
              </Box>
            )}
            {selectedTab === 1 && (
              <LMCComments
                data={LMCWithCommentsData}
                loadingState={loadingState}
              />
            )}
            {selectedTab === 2 && (
              <CommentForm data={dataToUpdate} loadingState={loadingState} />
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
