import { Box } from "@mui/material";
import React from "react";
import EditableTable from "components/smea/Table/EditableTable";

export default function SchoolDataTable({ data, loadingState }) {
  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    { field: "formQuarter", headerName: "Quarter", width: 150 },
    {
      field: "formSubject",
      headerName: "Subject",
      width: 310,
    },
    {
      field: "formComponentSub",
      headerName: "Component",
      width: 150,
    },
    { field: "formGradeLevel", headerName: "Grade Level", width: 150 },
    {
      field: "overallMelcaverageMPS",
      headerName: "Average MPS",
      width: 150,
      valueGetter: (params) => {
        const value = parseFloat(params?.value).toFixed(2);
        return value;
      },
    },
    {
      field: "overallMelcaverageSD",
      headerName: "Average SD",
      width: 150,
      valueGetter: (params) => {
        const value = parseFloat(params?.value).toFixed(2);
        return value;
      },
    },
    {
      field: "status",
      headerName: "Submit Type",
      width: 150,
      valueGetter: (params) =>
        // eslint-disable-next-line no-nested-ternary
        params?.value === 1
          ? "Submitted"
          : params?.value === 0
          ? "Saved"
          : "Unknown",
    },
  ];

  return (
    <Box>
      <EditableTable
        data={data}
        columns={columns}
        loading={loadingState}
        // rowToView={setRowToView}
        singleSelect
        height="60vh"
        showSearch
      />
    </Box>
  );
}
