import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import EditableTable from "components/smea/Table/EditableTable";

export default function LMCs({ data, loadingState, setDataToUpdate }) {
  const [selectedLMC, setSelectedLMC] = useState([]);

  useEffect(() => {
    setDataToUpdate(selectedLMC);
  }, [selectedLMC]);

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: () => (
        <Button
          sx={{
            backgroundColor: "green",
            color: "#fff",
            fontSize: "10px",
            fontWeight: "bold",
            padding: "3px 20px",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "lightgray",
              color: "#2f2f2f",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          Add comment
        </Button>
      ),
    },
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "gradeLevel",
      headerName: "Grade Level",
      width: 120,
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 200,
    },
    {
      field: "component",
      headerName: "Component",
      width: 200,
    },
    {
      field: "mps",
      headerName: "Least Mastered MPS",
      width: 200,
      valueGetter: (params) => {
        const mpsValue = params?.value;
        if (typeof mpsValue === "string") {
          return mpsValue;
        }
        if (Array.isArray(mpsValue)) {
          return mpsValue.map((entry) => Object.values(entry)[0]).join(",\n\n");
        }
        return "";
      },
    },
    {
      field: "melcs",
      headerName: "Least Mastered MELCs",
      width: 300,
      valueGetter: (params) => {
        const melcsValue = params?.value;
        if (typeof melcsValue === "string") {
          return melcsValue;
        }
        if (Array.isArray(melcsValue)) {
          return melcsValue
            .map((entry) => Object.values(entry)[0])
            .join(",\n\n");
        }
        return "";
      },
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <EditableTable
        data={data}
        columns={columns}
        loading={loadingState}
        singleSelect
        selectedData={setSelectedLMC}
        height="60vh"
      />
    </Box>
  );
}
