import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ConfirmationModal from "modals/smea/miscellaneous/ConfirmationModal";
import formService from "services/smea/form-service";
import PromptModal from "modals/smea/miscellaneous/PromptModal";
import { useStateContext } from "contexts/ContextProvider";

export default function EncodingForm({
  competencies,
  competencyDetails,
  onDetailsUpdate,
  submitFunction,
  setSelectedTab,
}) {
  const { auth } = useStateContext();
  const [responseDetails, setResponseDetails] = useState(
    competencies?.map((competency, index) => ({
      melcId: competency?.id || "",
      mps: competencyDetails?.data[index]?.mps || "",
      sd: competencyDetails?.data[index]?.sd || "",
    }))
  );
  const [mpsAverageTotal, setmpsAverageTotal] = useState(0);
  const [sdAverageTotal, setsdAverageTotal] = useState(0);
  const [disable, setDisable] = useState(true);

  const [openPromptModal, setOpenPromptModal] = useState(false);
  const [promptMssg, setPromptMssg] = useState("");

  const [open, setOpen] = useState(false);
  const [promptResponse, setPromptResponse] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [submitKind, setSubmitKind] = useState("");
  const [promptDesc, setPromptDesc] = useState("");

  const [mpsErrorMessages, setMpsErrorMessages] = useState({});
  const [sdErrorMessages, setSdErrorMessages] = useState({});

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const displayMpsError = (id, text) => {
    setMpsErrorMessages((prevErrors) => ({
      ...prevErrors,
      [id]: text,
    }));
    setTimeout(() => {
      setMpsErrorMessages((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }, 3000);
  };

  const displaySdError = (id, text) => {
    setSdErrorMessages((prevErrors) => ({
      ...prevErrors,
      [id]: text,
    }));
    setTimeout(() => {
      setSdErrorMessages((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }, 3000);
  };

  const handleOpen = (submitType) => {
    setSubmitKind(submitType);
    if (submitType === "save") {
      setPromptDesc(
        "Are you sure you want to save? Proceeding will finalize your changes, but you can still edit them afterwards."
      );
    } else if (submitType === "submit") {
      setPromptDesc(
        "Are you sure you want to submit? Once submitted, all changes will be final and irreversible."
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (submitType) => {
    setDisable(true);

    formService
      .filterMELCS({
        quarterId: competencies[0]?.quarterId || null,
        relatedGradeLevelId: competencies[0]?.relatedGradeLevelId || null,
        relatedSubjectId: competencies[0]?.relatedSubjectId,
        component:
          competencies[0].relatedSubjectId === 6
            ? null
            : competencies[0].componentId || null,
        officeId: auth.officeId,
      })
      .then((e) => {
        if (e.hasEncoded === true) {
          setOpenPromptModal(true);
          setPromptMssg("This set of MELCs has already been encoded.");
          setSelectedTab(0);
        } else {
          handleOpen(submitType);
        }
      })
      .catch((err) => {
        setOpenPromptModal(true);
        setPromptMssg(err.message);
      })
      .finally(() => {
        setDisable(false);
      });
  };

  useEffect(() => {
    const addToData = responseDetails?.map((response) => ({
      melcId: response?.melcId || undefined,
      mps: response?.mps || undefined,
      sd: response?.sd || undefined,
    }));

    let mpsTotal = 0;
    let mpsAverage = 0;
    let sdTotal = 0;
    let sdAverage = 0;
    responseDetails?.forEach((response) => {
      if (response?.mps !== null || response?.mps !== undefined) {
        mpsTotal =
          parseFloat(mpsTotal, 10) + parseFloat(response?.mps || 0, 10);
      }

      if (response?.sd !== null || response?.sd !== undefined) {
        sdTotal = parseFloat(sdTotal, 10) + parseFloat(response?.sd || 0, 10);
      }
    });

    const nonNullMpsResponses = responseDetails?.filter(
      (response) => response?.mps
    );

    const nonNullSdResponses = responseDetails?.filter(
      (response) => response?.sd
    );

    setDisable(
      nonNullMpsResponses.length !== nonNullSdResponses.length ||
        nonNullMpsResponses.length < 1
    );

    mpsAverage =
      nonNullMpsResponses.length > 0
        ? parseFloat(mpsTotal, 10) / nonNullMpsResponses.length
        : 0;

    sdAverage =
      nonNullMpsResponses.length > 0
        ? parseFloat(sdTotal, 10) / nonNullMpsResponses.length
        : 0;

    setmpsAverageTotal(parseFloat(mpsAverage).toFixed(2));
    setsdAverageTotal(parseFloat(sdAverage).toFixed(2));

    onDetailsUpdate({
      status: promptResponse,
      data: addToData,
      mpsAverageTotal: parseFloat(mpsAverage).toFixed(2),
      sdAverageTotal: parseFloat(sdAverage).toFixed(2),
    });
  }, [responseDetails, promptResponse]);

  const handleResponseChange = (index, value, sd, competency) => {
    // setQuestionDetails(question)
    setResponseDetails((prevResponse) => {
      const newResponse = [...prevResponse];
      newResponse[index] = {
        mps: value || undefined,
        sd: value ? sd : null || undefined,
        melcId: competency.id,
        ...competency,
      };
      return newResponse;
    });
  };

  // useEffect(() => {
  //   const arrayHasUndefined = responseDetails.some(
  //     (obj) =>
  //       obj?.sd === undefined ||
  //       obj?.mps === undefined ||
  //       obj === undefined ||
  //       obj?.sd === null ||
  //       obj?.mps === null ||
  //       obj === null
  //   );
  //   if (
  //     arrayHasUndefined ||
  //     responseDetails.length !== competencies?.length ||
  //     responseDetails.length === 0
  //   ) {
  //     setDisable(true);
  //   } else {
  //     setDisable(false);
  //   }
  // }, [responseDetails]);

  const handleKeyDown = (event, index, category) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }

    if (event.key === "Enter") {
      // Prevent the default behavior of the Enter key (e.g., form submission)
      event.preventDefault();

      if (category === "mps") {
        // Find the next text field and focus on it
        const nextIndex = (index + 1) % competencies.length;
        const nextTextField = document.getElementById(
          `mpsTextField_${nextIndex}`
        );
        if (nextTextField) {
          nextTextField.focus();
        }
      } else {
        // Find the next text field and focus on it
        const nextIndex = (index + 1) % competencies.length;

        // Find the next enabled text field
        let currentIndex = nextIndex;
        let nextTextField = null;

        while (currentIndex !== index) {
          nextTextField = document.getElementById(
            `sdTextField_${currentIndex}`
          );

          if (nextTextField && !nextTextField.disabled) {
            break;
          }

          currentIndex = (currentIndex + 1) % competencies.length;
        }

        if (nextTextField) {
          nextTextField.focus();
        }
      }
    }
  };

  const handleWheel = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (submit !== false && promptResponse !== null) {
      submitFunction({ status: promptResponse });
    }

    setSubmit(false);
    setPromptResponse(null);
  }, [submit, promptResponse]);

  // console.log(competencies);

  return (
    <Box
      sx={{
        mt: 5,
        width: "100%",
      }}
    >
      <PromptModal
        handleClose={() => setOpenPromptModal(false)}
        open={openPromptModal}
        prompt={promptMssg}
      />
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        setPromptResponse={setPromptResponse}
        setSubmit={setSubmit}
        submitKind={submitKind}
        promptDesc={promptDesc}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          mb: 5,
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mb: 2,
            width: "95%",
          }}
        >
          <Typography fontWeight="bold" mr={1}>
            Kindly fill up the competencies with their corresponding MPS and
            Standard Deviation values.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "95%",
            }}
          >
            <Typography fontStyle="italic" fontSize="15px" color="gray" mr={1}>
              (Some MELCs can be left blank)
            </Typography>
            <Typography fontStyle="italic" fontSize="15px" color="gray">
              (To navigate between text fields, use the {`"`}Tab{`"`} key to
              move horizontally and the {`"`}Enter{`"`} key to move vertically.)
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            // flexDirection: "column",
            justifyContent: "space-between",
            // alignItems: "center",
            textAlign: "center",
            width: "95%",
            mb: 2,
            // border: "solid 1px black",
            boxShadow: "3px 3px 8px 3px rgba(100, 100, 100, 0.5)",
            borderRadius: "10px",
            py: 2,
            px: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "20px" }}>Form Description:</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "lightgray",
              px: 0.2,
              mx: 2,
              borderRadius: "10px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              // width: "90%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "20%",
                  minWidth: "250px",
                }}
              >
                <Typography
                  sx={{
                    color: "#2f2f2f",
                    fontStyle: "italic",
                    fontSize: "15px",
                  }}
                >
                  Subject:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {competencies[0]?.subject || "N/A"}
                </Typography>
              </Box>
              <Box sx={{ width: "20%", minWidth: "250px" }}>
                <Typography
                  sx={{
                    color: "#2f2f2f",
                    fontStyle: "italic",
                    fontSize: "15px",
                  }}
                >
                  Grade Level:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {competencies[0]?.gradeLevel || "N/A"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "20%", minWidth: "250px" }}>
                <Typography
                  sx={{
                    color: "#2f2f2f",
                    fontStyle: "italic",
                    fontSize: "15px",
                  }}
                >
                  Component:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {competencies[0]?.relatedSubjectId === 6
                    ? "N/A"
                    : competencies[0]?.component
                    ? competencies[0]?.component
                    : "N/A"}
                </Typography>
              </Box>
              <Box sx={{ width: "20%", minWidth: "250px" }}>
                <Typography
                  sx={{
                    color: "#2f2f2f",
                    fontStyle: "italic",
                    fontSize: "15px",
                  }}
                >
                  Quarter:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {competencies[0]?.quarter || "N/A"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {competencies?.map((competency, index) => (
          <React.Fragment key={competency.id}>
            <Box
              sx={{
                width: "95%",
                my: 1,
                border: "solid 1px lightgray",
                borderRadius: "10px",
                // boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.8)",
                p: 2,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid container sx={{ mr: 2 }}>
                  <Grid
                    item
                    xs={isSmallScreen ? 12 : 6}
                    sx={{ display: "flex", my: 2 }}
                  >
                    <Typography sx={{ mr: 1 }}>{index + 1}.</Typography>
                    <Typography>{`${
                      competency.melcNo ? `${competency.melcNo}: ` : ""
                    } ${competency?.melc}`}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={isSmallScreen ? 12 : 6}
                    sx={{
                      display: "flex",
                      "@media (max-width: 650px)": {
                        flexWrap: "wrap",
                      },
                      my: 2,
                    }}
                  >
                    <TextField
                      id={`mpsTextField_${index}`}
                      label="MPS Value"
                      variant="outlined"
                      type="text"
                      onWheel={handleWheel}
                      size="small"
                      value={
                        responseDetails[index]?.mps !== undefined &&
                        responseDetails[index]?.mps !== null
                          ? responseDetails[index]?.mps
                          : null
                      }
                      onChange={(evt) =>
                        handleResponseChange(
                          index,
                          evt.target.value,
                          responseDetails[index]?.sd,
                          competency
                        )
                      }
                      helperText={
                        <span style={{ color: "red" }}>
                          {mpsErrorMessages[index]}
                        </span>
                      }
                      onInput={(evt) => {
                        let { value } = evt.target;
                        // Replace all characters that are not digits or a single decimal point
                        value = value?.replace(/[^\d.]/g, "");
                        // Limit decimal points to two
                        const decimalIndex = value.indexOf(".");
                        if (
                          decimalIndex !== -1 &&
                          value.slice(decimalIndex + 1).length > 2
                        ) {
                          value = value.slice(0, decimalIndex + 3);
                          displayMpsError(
                            index,
                            "Only up to two decimal points are allowed."
                          );
                        }
                        if (parseFloat(value, 10) > 100) {
                          value = responseDetails[index]?.mps;
                          displayMpsError(
                            index,
                            "Value should not exceed 100."
                          );
                        }
                        // eslint-disable-next-line no-param-reassign
                        evt.target.value = value;
                      }}
                      InputProps={{
                        pattern: "[0-9]*",
                        title: "Please enter a valid number",
                        style: {
                          textAlign: "right",
                        },
                        sx: {
                          "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                            {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                        },
                      }}
                      autoComplete="off"
                      onKeyDown={(evt) => handleKeyDown(evt, index, "mps")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "yellow",
                          // transition: "border-color 0.2s ease-in-out",
                        },
                        minWidth: "45%",
                        mr: 2,
                        "@media (max-width: 650px)": {
                          mr: 0,
                          mb: 2,
                        },
                      }}
                    />

                    <TextField
                      id={`sdTextField_${index}`}
                      label="Standard Deviation"
                      variant="outlined"
                      onWheel={handleWheel}
                      size="small"
                      disabled={!responseDetails[index]?.mps}
                      value={
                        responseDetails[index]?.sd !== undefined &&
                        responseDetails[index]?.sd !== null
                          ? responseDetails[index]?.sd
                          : ""
                      }
                      onChange={(evt) =>
                        handleResponseChange(
                          index,
                          responseDetails[index]?.mps,
                          responseDetails[index]?.mps !== undefined
                            ? evt.target.value
                            : null,
                          competency
                        )
                      }
                      onInput={(evt) => {
                        let { value } = evt.target;
                        // Replace all characters that are not digits or a single decimal point
                        value = value?.replace(/[^\d.]/g, "");
                        // Limit decimal points to two
                        const decimalIndex = value.indexOf(".");
                        if (
                          decimalIndex !== -1 &&
                          value.slice(decimalIndex + 1).length > 2
                        ) {
                          value = value.slice(0, decimalIndex + 3);
                          displaySdError(
                            index,
                            "Up to two decimal points only"
                          );
                        }
                        if (parseFloat(value, 10) > 10) {
                          value =
                            responseDetails[index]?.sd === undefined ||
                            responseDetails[index]?.sd === null
                              ? ""
                              : responseDetails[index]?.sd;
                          displaySdError(index, "Value should not exceed 10.");
                        }
                        // eslint-disable-next-line no-param-reassign
                        evt.target.value = value;
                      }}
                      helperText={
                        !responseDetails[index]?.mps ? (
                          "Not Applicable"
                        ) : (
                          <span style={{ color: "red" }}>
                            {sdErrorMessages[index]}
                          </span>
                        )
                      }
                      InputProps={{
                        pattern: "[0-9]*",
                        title: "Please enter a valid number",
                        style: {
                          textAlign: "right",
                        },
                        sx: {
                          "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                            {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                        },
                      }}
                      autoComplete="off"
                      onKeyDown={(evt) => handleKeyDown(evt, index, "sd")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "yellow",
                          // transition: "border-color 0.2s ease-in-out",
                        },
                        minWidth: "45%",
                      }}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    minWidth: "100px",
                    textAlign: "right",
                    color: "#919191",
                    // color: "black",
                  }}
                >
                  <Typography>{competency?.week}</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {competency.relatedSubjectId === 6
                      ? competency?.component
                      : null}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </React.Fragment>
        ))}
        <Grid container mt={4}>
          <Grid item xs={isSmallScreen ? 12 : 6} />
          <Grid
            item
            xs={isSmallScreen ? 12 : 6}
            sx={{ display: "flex", pl: isSmallScreen ? 4 : 0 }}
          >
            <TextField
              label="Average MPS"
              variant="outlined"
              type="number"
              size="small"
              value={mpsAverageTotal}
              InputProps={{
                sx: {
                  "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                },
              }}
              sx={{ mx: 2 }}
            />
            <TextField
              label="Average SD"
              variant="outlined"
              type="number"
              size="small"
              value={sdAverageTotal}
              InputProps={{
                sx: {
                  "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                },
              }}
              sx={{ mx: 2 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10vh",
        }}
      >
        <Button
          onClick={() => handleSubmit("submit")}
          disabled={disable}
          sx={{
            display: "block",
            backgroundColor: disable ? "lightgray" : "#178ef7",
            color: "#fff",
            p: "10px",
            width: "20%",
            minWidth: "100px",
            mr: 4,
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
            transition: "ease-in-out 0.3s",
            fontWeight: "bold",
            "&:hover": {
              boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.7)",
              backgroundColor: "lightgray",
              color: "black",
            },
          }}
        >
          Submit
        </Button>
        <Button
          onClick={() => handleSubmit("save")}
          disabled={disable}
          sx={{
            display: "block",
            backgroundColor: disable ? "lightgray" : "#178ef7",
            color: "#fff",
            p: "10px",
            width: "20%",
            minWidth: "100px",
            transition: "ease-in-out 0.3s",
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
            "&:hover": {
              fontStyle: "none",
              boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.7)",
              backgroundColor: "lightgray",
              color: "black",
            },
          }}
        >
          Save
        </Button>
      </Box>
      {disable && (
        <Typography
          sx={{ textAlign: "center", color: "gray", fontSize: "14px", mt: 2 }}
        >
          Please fill up the necessary textfields
        </Typography>
      )}
    </Box>
  );
}
