import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import ConfirmationModal from "../miscellaneous/ConfirmationModal";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "90vh",
    width: "70vw",
  },
  minWidth: "600px",
  overflow: "auto",
};

export default function UpdateDataModal({
  open,
  handleClose,
  data,
  updatedData,
  onDataUpdate,
  selectedData,
  submitFunction,
}) {
  const [responseDetails, setResponseDetails] = useState(
    data.map((competency, index) => ({
      melcId: competency?.melcId,
      mps: parseFloat(updatedData[index]?.mps),
      sd: parseFloat(updatedData[index]?.sd),
    }))
  );

  const [dataInfo, setDataInfo] = useState(
    data.map((details) => ({
      week: details?.week,
      component: details?.component,
    }))
  );

  const [openPrompt, setOpenPrompt] = useState(false);
  const [promptResponse, setPromptResponse] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [submitKind, setSubmitKind] = useState("");
  const [promptDesc, setPromptDesc] = useState("");

  const [disable, setDisable] = useState(true);
  const [mpsAverageTotal, setmpsAverageTotal] = useState(0);
  const [sdAverageTotal, setsdAverageTotal] = useState(0);

  const [mpsErrorMessages, setMpsErrorMessages] = useState({});
  const [sdErrorMessages, setSdErrorMessages] = useState({});

  const displayMpsError = (id, text) => {
    setMpsErrorMessages((prevErrors) => ({
      ...prevErrors,
      [id]: text,
    }));
    setTimeout(() => {
      setMpsErrorMessages((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }, 3000);
  };

  const displaySdError = (id, text) => {
    setSdErrorMessages((prevErrors) => ({
      ...prevErrors,
      [id]: text,
    }));
    setTimeout(() => {
      setSdErrorMessages((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }, 3000);
  };

  const handleOpenPrompt = (submitType) => {
    setSubmitKind(submitType);
    if (submitType === "save") {
      setPromptDesc(
        "Are you sure you want to save? Proceeding will finalize your changes, but you can still edit them afterwards."
      );
    } else if (submitType === "submit") {
      setPromptDesc(
        "Are you sure you want to submit? Once submitted, all changes will be final and irreversible."
      );
    }
    setOpenPrompt(true);
  };

  const handleClosePrompt = () => {
    setOpenPrompt(false);
  };

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    const addToData = responseDetails?.map((response) => ({
      melcId: response?.melcId || undefined,
      mps: response?.mps || undefined,
      sd: response?.sd || undefined,
    }));

    let mpsTotal = 0;
    let mpsAverage = 0;
    let sdTotal = 0;
    let sdAverage = 0;
    responseDetails?.forEach((response) => {
      if (response?.mps !== null || response?.mps !== undefined) {
        mpsTotal =
          parseFloat(mpsTotal, 10) + parseFloat(response?.mps || 0, 10);
      }

      if (response?.sd !== null || response?.sd !== undefined) {
        sdTotal = parseFloat(sdTotal, 10) + parseFloat(response?.sd || 0, 10);
      }
    });

    const nonNullMpsResponses = responseDetails?.filter(
      (response) => response?.mps
    );

    const nonNullSdResponses = responseDetails?.filter(
      (response) => response?.sd
    );

    setDisable(
      nonNullMpsResponses.length !== nonNullSdResponses.length ||
        nonNullMpsResponses.length < 1
    );

    mpsAverage =
      nonNullMpsResponses.length > 0
        ? parseFloat(mpsTotal, 10) / nonNullMpsResponses.length
        : 0;

    sdAverage =
      nonNullMpsResponses.length > 0
        ? parseFloat(sdTotal, 10) / nonNullMpsResponses.length
        : 0;

    setmpsAverageTotal(parseFloat(mpsAverage).toFixed(2));
    setsdAverageTotal(parseFloat(sdAverage).toFixed(2));

    onDataUpdate({
      status: promptResponse,
      data: addToData,
      averageMPS: mpsAverage,
      averageSD: sdAverage,
    });
  }, [responseDetails, promptResponse]);

  const handleResponseChange = (index, value, sd, competency) => {
    const newData = [...responseDetails];
    newData[index] = {
      ...newData[index],
      melcId: competency.melcId,
      mps: value || undefined,
      sd: value ? sd : null || undefined,
    };
    setResponseDetails(newData);
  };

  const handleKeyDown = (event, index, category) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }

    if (event.key === "Enter") {
      // Prevent the default behavior of the Enter key (e.g., form submission)
      event.preventDefault();

      if (category === "mps") {
        // Find the next text field and focus on it
        const nextIndex = (index + 1) % data.length;
        const nextTextField = document.getElementById(
          `mpsTextField_${nextIndex}`
        );
        if (nextTextField) {
          nextTextField.focus();
        }
      } else {
        // Find the next text field and focus on it
        const nextIndex = (index + 1) % data.length;

        // Find the next enabled text field
        let currentIndex = nextIndex;
        let nextTextField = null;

        while (currentIndex !== index) {
          nextTextField = document.getElementById(
            `sdTextField_${currentIndex}`
          );

          if (nextTextField && !nextTextField.disabled) {
            break;
          }

          currentIndex = (currentIndex + 1) % data.length;
        }

        if (nextTextField) {
          nextTextField.focus();
        }
      }
    }
  };

  const handleWheel = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (submit !== false && promptResponse !== null) {
      submitFunction(promptResponse);
    }
    setSubmit(false);
    setPromptResponse(null);
  }, [submit, promptResponse]);

  useEffect(() => {
    setResponseDetails(
      data.map((competency) => ({
        melcId: competency.melcId,
        mps: competency.mps,
        sd: competency.sd,
      }))
    );
    setDataInfo(
      data.map((details) => ({
        week: details?.week,
        component: details?.component,
      }))
    );
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box sx={style}>
        <ConfirmationModal
          open={openPrompt}
          handleClose={handleClosePrompt}
          setPromptResponse={setPromptResponse}
          setSubmit={setSubmit}
          submitKind={submitKind}
          promptDesc={promptDesc}
        />
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundColor: "blue",
              borderRadius: "10px",
              width: "70%",
              p: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{ ml: 4, fontSize: 25, fontWeight: "bold", color: "#fff" }}
            >
              UPDATE DATA
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              // height: "50vh",
              overflowY: "auto",
              p: 4,
              m: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                // flexDirection: "column",
                justifyContent: "space-between",
                // alignItems: "center",
                textAlign: "center",
                width: "95%",
                mb: 2,
                // border: "solid 1px black",
                boxShadow: "3px 3px 8px 3px rgba(100, 100, 100, 0.5)",
                borderRadius: "10px",
                py: 2,
                px: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "20px" }}>
                  Form Description:
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "lightgray",
                  px: 0.2,
                  mx: 2,
                  borderRadius: "10px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  // width: "90%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "20%",
                      minWidth: "200px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#2f2f2f",
                        fontStyle: "italic",
                        fontSize: "15px",
                      }}
                    >
                      Subject:
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {selectedData?.formSubject || "N/A"}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "20%", minWidth: "200px" }}>
                    <Typography
                      sx={{
                        color: "#2f2f2f",
                        fontStyle: "italic",
                        fontSize: "15px",
                      }}
                    >
                      Grade Level:
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {selectedData?.formGradeLevel || "N/A"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "20%", minWidth: "200px" }}>
                    <Typography
                      sx={{
                        color: "#2f2f2f",
                        fontStyle: "italic",
                        fontSize: "15px",
                      }}
                    >
                      Component:
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {selectedData?.formSubjectId === 6
                        ? "N/A"
                        : selectedData?.formComponentSub
                        ? selectedData?.formComponentSub
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "20%", minWidth: "200px" }}>
                    <Typography
                      sx={{
                        color: "#2f2f2f",
                        fontStyle: "italic",
                        fontSize: "15px",
                      }}
                    >
                      Quarter:
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {selectedData?.formQuarter || "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {responseDetails?.map((item, index) => (
              <React.Fragment key={item.id}>
                <Box
                  sx={{
                    width: "95%",
                    my: 1,
                    border: "solid 1px lightgray",
                    borderRadius: "10px",
                    position: "relative",
                    // boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.8)",
                    p: 2,
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={isSmallScreen ? 12 : 6}
                        sx={{ display: "flex", my: 2 }}
                      >
                        <Typography sx={{ mr: 1 }}>{index + 1}.</Typography>
                        <Typography>{`${data[index]?.melcNumber}: ${data[index]?.melcName}`}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={isSmallScreen ? 12 : 6}
                        sx={{ display: "flex", my: 2 }}
                      >
                        <TextField
                          id={`mpsTextField_${index}`}
                          label="MPS Value"
                          variant="outlined"
                          type="text"
                          size="small"
                          onWheel={handleWheel}
                          value={
                            item?.mps !== undefined && item?.mps !== null
                              ? item?.mps
                              : null
                          }
                          onChange={(evt) =>
                            handleResponseChange(
                              index,
                              evt.target.value,
                              item?.sd,
                              data[index]
                            )
                          }
                          helperText={mpsErrorMessages[index]}
                          onInput={(evt) => {
                            let { value } = evt.target;
                            // Replace all characters that are not digits or a single decimal point
                            value = value?.replace(/[^\d.]/g, "");
                            // Limit decimal points to two
                            const decimalIndex = value.indexOf(".");
                            if (
                              decimalIndex !== -1 &&
                              value.slice(decimalIndex + 1).length > 2
                            ) {
                              value = value.slice(0, decimalIndex + 3);
                              displayMpsError(
                                index,
                                "Only up to two decimal points are allowed."
                              );
                            }
                            if (parseFloat(value, 10) > 100) {
                              value = responseDetails[index]?.mps;
                              displayMpsError(
                                index,
                                "Value should not exceed 100."
                              );
                            }
                            // eslint-disable-next-line no-param-reassign
                            evt.target.value = value;
                          }}
                          InputProps={{
                            pattern: "[0-9]*",
                            title: "Please enter a valid number",
                            style: {
                              textAlign: "right",
                            },
                            sx: {
                              "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                                {
                                  "-webkit-appearance": "none",
                                  margin: 0,
                                },
                            },
                          }}
                          autoComplete="off"
                          onKeyDown={(evt) => handleKeyDown(evt, index, "mps")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "yellow",
                              // transition: "border-color 0.2s ease-in-out",
                            },
                            mx: 2,
                          }}
                        />

                        <Box sx={{ display: "block" }}>
                          <TextField
                            id={`sdTextField_${index}`}
                            label="Standard Deviation"
                            variant="outlined"
                            size="small"
                            onWheel={handleWheel}
                            disabled={!item?.mps}
                            value={
                              item?.sd !== undefined && item?.sd !== null
                                ? item?.sd
                                : ""
                            }
                            onChange={(evt) =>
                              handleResponseChange(
                                index,
                                item?.mps,
                                item?.mps !== undefined
                                  ? evt.target.value
                                  : null,
                                data[index]
                              )
                            }
                            onInput={(evt) => {
                              let { value } = evt.target;
                              // Replace all characters that are not digits or a single decimal point
                              value = value?.replace(/[^\d.]/g, "");
                              // Limit decimal points to two
                              const decimalIndex = value.indexOf(".");
                              if (
                                decimalIndex !== -1 &&
                                value.slice(decimalIndex + 1).length > 2
                              ) {
                                value = value.slice(0, decimalIndex + 3);
                                displaySdError(
                                  index,
                                  "Up to two decimal points only"
                                );
                              }
                              if (parseFloat(value, 10) > 10) {
                                value =
                                  responseDetails[index]?.sd === undefined ||
                                  responseDetails[index]?.sd === null
                                    ? ""
                                    : responseDetails[index]?.sd;
                                displaySdError(
                                  index,
                                  "Value should not exceed 10."
                                );
                              }
                              // eslint-disable-next-line no-param-reassign
                              evt.target.value = value;
                            }}
                            helperText={
                              !responseDetails[index]?.mps
                                ? "Not Counted"
                                : sdErrorMessages[index]
                            }
                            InputProps={{
                              pattern: "[0-9]*",
                              title: "Please enter a valid number",
                              style: {
                                textAlign: "right",
                              },
                              sx: {
                                "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                  },
                              },
                            }}
                            autoComplete="off"
                            onKeyDown={(evt) => handleKeyDown(evt, index, "sd")}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        minWidth: "100px",
                        textAlign: "right",
                        color: "#919191",
                        // color: "black",
                      }}
                    >
                      <Typography>
                        {dataInfo && dataInfo[index]?.week}
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {selectedData?.formSubjectId === 6
                          ? (dataInfo && dataInfo[index]?.component) || null
                          : null}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </React.Fragment>
            ))}
            <Grid container mt={4}>
              <Grid item xs={6} />
              <Grid item xs={6} sx={{ display: "flex" }}>
                <TextField
                  label="Average MPS"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={mpsAverageTotal}
                  InputProps={{
                    sx: {
                      "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                        {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                    },
                  }}
                  sx={{ mx: 2 }}
                />
                <TextField
                  label="Average SD"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={sdAverageTotal}
                  InputProps={{
                    sx: {
                      "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                        {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                    },
                  }}
                  sx={{ mx: 2 }}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                p: 2,
                mt: 2,
              }}
            >
              <Button
                onClick={() => handleOpenPrompt("submit")}
                disabled={disable}
                sx={{
                  display: "block",
                  backgroundColor: disable ? "lightgray" : "blue",
                  color: "#fff",
                  p: "10px",
                  width: "20%",
                  minWidth: "100px",
                  mr: 4,
                  transition: "ease-in-out 0.3s",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                  fontWeight: "bold",
                  "&:hover": {
                    fontStyle: "none",
                    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.7)",
                    backgroundColor: "lightgray",
                    color: "black",
                  },
                }}
              >
                Submit
              </Button>
              <Button
                onClick={() => handleOpenPrompt("save")}
                disabled={disable}
                sx={{
                  display: "block",
                  backgroundColor: disable ? "lightgray" : "blue",
                  color: "#fff",
                  p: "10px",
                  width: "20%",
                  minWidth: "100px",
                  transition: "ease-in-out 0.3s",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                  fontWeight: "bold",
                  "&:hover": {
                    fontStyle: "none",
                    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.7)",
                    backgroundColor: "lightgray",
                    color: "black",
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
