import React from "react";
import TextField from "@mui/material/TextField";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function TextFieldDatePicker({ label, value, onChange, sx }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value || ""}
        onChange={(newValue) => {
          onChange?.(newValue);
        }}
        renderInput={(params) => {
          <TextField
            {...params}
            error={false}
            sx={{ color: "#fff" }}
            fullWidth
          />;
        }}
        slotProps={{
          textField: {
            size: "small",
            error: false,
          },
        }}
        sx={sx}
      />
    </LocalizationProvider>
  );
}
