/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import formService from "services/smea/form-service";
import { useStateContext } from "contexts/ContextProvider";
import UpdateDataModal from "modals/smea/results/UpdateDataModal";
import ViewDataModal from "modals/smea/results/ViewDataModal";
import exportFunction from "functions/smea/export-function";
import SnackbarComponent from "components/smea/Snackbar";
import SelectQuarter from "components/smea/Textfields/SelectQuarter";
import useAxiosPrivate from "contexts/interceptors/axios";
import DataTable from "./DataTable";

export default function Data() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [dataUpdate, setDataUpdate] = useState([]);
  const [status, setStatus] = useState(null);
  const [quarter, setQuarter] = useState(3);
  const [dataResults, setDataResults] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disableView, setDisableView] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleOpen = (openType) => {
    setType(openType);
    if (selectedData) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    // formService
    //   .getResults(quarter)
    axiosPrivate
      .get(`/competency/getAllResults`, { params: { quarterId: quarter } })
      .then((res) => {
        const results = res.data;
        let filteredResults = [];

        filteredResults = results.filter(
          (result) => parseInt(status, 10) === parseInt(result.status, 10)
        );

        if (filteredResults?.length === 0 && status === null) {
          filteredResults = results;
        }

        if (auth?.role === "teacher") {
          filteredResults = filteredResults.filter(
            (result) => result.formOfficeId === auth?.officeId
          );
        }

        setDataResults(filteredResults);
      })
      .catch((err) => {
        console.log(err);
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOverallExport = () => {
    setLoading(true);
    setError("");

    if (quarter) {
      const exportApiCall =
        auth.role === "teacher"
          ? formService.exportSchoolOverallData(auth.officeId, quarter)
          : formService.exportOverallData(quarter);

      exportApiCall
        .then((results) => {
          exportFunction.downloadData(results, auth.officeName);
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError("Quarter is not specified");
    }
  };

  const handleGetDataEntryByID = () => {
    setLoading(true);
    setError("");

    formService
      .getResultsById(selectedData[0]?.responseId)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, [status, quarter]);

  useEffect(() => {
    if (selectedData) {
      if (selectedData?.length !== 0) {
        handleGetDataEntryByID();
      }
    }
  }, [selectedData]);

  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      if (selectedData[0]?.status === 1 && auth?.role === "teacher") {
        setDisableEdit(true);
        setDisableView(false);
      } else {
        setDisableEdit(false);
        setDisableView(false);
      }
    } else {
      setDisableEdit(true);
      setDisableView(true);
    }
  }, [selectedData]);

  const handleUpdateData = (submitType) => {
    setLoading(true);
    setError("");

    formService
      .formUpdate(selectedData[0]?.id, {
        ...dataUpdate,
        responseId: selectedData[0]?.responseId,
        status: submitType,
        data: dataUpdate.data.map((values) => ({
          melcId: values.melcId,
          mps: parseFloat(values.mps) || null,
          sd: parseFloat(values.sd) || null,
        })),
      })
      .then(() => {
        setOpenSuccess(true);
        handleGetAll();
        setOpen(false);
      })
      .catch((err) => {
        setOpenError(true);
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        p: "20px",
        overflowX: "auto",
        width: "100%",
      }}
    >
      <UpdateDataModal
        open={type === "update" && open}
        handleClose={handleClose}
        data={data}
        updatedData={dataUpdate.data}
        onDataUpdate={setDataUpdate}
        selectedData={selectedData && selectedData[0]}
        submitFunction={handleUpdateData}
      />
      <ViewDataModal
        open={type === "view" && open}
        handleClose={handleClose}
        data={data}
      />

      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="1rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Divider>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: "25px",
            }}
          >
            Encoded Data
          </Typography>
        </Divider>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
            mt: -0.5,
            minWidth: "30%",
            right: 280,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "gray",
                mr: 2,
                py: 1.5,
              }}
            >
              Submit Type:
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Button
                disabled={status !== 0 && status !== null}
                onClick={() => setStatus(1)}
                sx={{
                  backgroundColor: "lightgray",
                  border:
                    status !== 0 && status !== null ? "solid 1px gray" : "none",
                  fontWeight: status === 1 && "bold",
                  color: "black",
                  width: "8vw",
                  minWidth: "100px",
                  mx: 1,
                  borderRadius: "15px",
                  "&:hover": {
                    backgroundColor: "#acacac",
                  },
                }}
              >
                Submitted
              </Button>
              <Button
                disabled={status !== 1 && status !== null}
                onClick={() => setStatus(0)}
                sx={{
                  backgroundColor: "lightgray",
                  border:
                    status !== 1 && status !== null ? "solid 1px gray" : "none",
                  fontWeight: status === 0 && "bold",
                  color: "black",
                  width: "8vw",
                  minWidth: "100px",
                  mx: 1,
                  borderRadius: "15px",
                  "&:hover": {
                    backgroundColor: "#acacac",
                  },
                }}
              >
                Saved
              </Button>
              {status !== null && (
                <Tooltip title="Remove Filter" placement="top">
                  <IconButton onClick={() => setStatus(null)}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box>
            <SelectQuarter
              label="Select Quarter"
              name="quarter"
              value={quarter}
              onChange={(fieldName, selectedValue) => {
                setQuarter(selectedValue);
              }}
              sx={{
                width: "100%",
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black !important",
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          {error}
          <DataTable
            data={dataResults}
            setSelectedData={setSelectedData}
            loadingState={loading}
            updateTableFunction={handleGetAll}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Button
              onClick={() => handleOpen("view")}
              disabled={disableView}
              sx={{
                backgroundColor: disableView ? "lightgray" : "blue",
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                mr: 4,
                borderRadius: "5px",
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                "&:hover": {
                  color: "black",
                  backgroundColor: "lightgray",
                },
              }}
            >
              View Data
            </Button>
            <Button
              onClick={() => handleOpen("update")}
              disabled={disableEdit}
              sx={{
                backgroundColor: disableEdit ? "lightgray" : "blue",
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                mr: 4,
                borderRadius: "5px",
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                "&:hover": {
                  color: "black",
                  backgroundColor: "lightgray",
                },
              }}
            >
              Edit Data
            </Button>
          </Box>

          <Box>
            <Button
              onClick={() => handleOverallExport()}
              disabled={!quarter}
              sx={{
                backgroundColor: "#4f668c",
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                minWidth: "210px",
                borderRadius: "5px",
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                "&:hover": {
                  color: "black",
                  backgroundColor: "lightgray",
                },
              }}
            >
              Export Overall Data
            </Button>
          </Box>
        </Box>
        <SnackbarComponent
          open={openSuccess}
          onClose={() => setOpenSuccess(false)}
          severity="success"
          message="Data Updated Successfully."
        />
        <SnackbarComponent
          open={openError}
          onClose={() => setOpenError(false)}
          severity="error"
          message={error}
        />
      </Box>
    </Box>
  );
}
