import DashboardIcon from "@mui/icons-material/Dashboard";
import GradeLevelsIcon from "@mui/icons-material/FormatListNumbered";
import MELCIcon from "@mui/icons-material/Summarize";
// import MELCIcon from "@mui/icons-material/ListAlt";
import OfficeIcon from "@mui/icons-material/Business";
import OfficeTypeIcon from "@mui/icons-material/BusinessTwoTone";
import SubjectIcon from "@mui/icons-material/MenuBook";
import ComponentsIcon from "@mui/icons-material/MenuBookTwoTone";
import UsersIcon from "@mui/icons-material/PeopleAlt";
import EncodingFormIcon from "@mui/icons-material/EditNote";
import DataIcon from "@mui/icons-material/Description";

const links = [
  {
    title: "Home",
    role: "superadmin",
    links: [
      {
        name: "Dashboard",
        path: "dashboard",
        icon: <DashboardIcon />,
      },
    ],
  },

  {
    title: "MELCs",
    role: "superadmin",
    links: [
      {
        name: "MELCs",
        path: "melcs",
        icon: <MELCIcon />,
      },
    ],
  },
  {
    title: "Form",
    role: "teacher",
    links: [
      {
        name: "Encoding Form",
        path: "form",
        icon: <EncodingFormIcon />,
      },
    ],
  },

  {
    title: "Libraries",
    role: "admin",
    links: [
      {
        name: "Grade Levels",
        path: "gradelevels",
        icon: <GradeLevelsIcon />,
      },
      {
        name: "Schools/Offices",
        path: "offices",
        icon: <OfficeIcon />,
      },
      {
        name: "School/Office Types",
        path: "officeTypes",
        icon: <OfficeTypeIcon />,
      },
      {
        name: "Subjects",
        path: "subjects",
        icon: <SubjectIcon />,
      },
      {
        name: "Components",
        path: "components",
        icon: <ComponentsIcon />,
      },
      {
        name: "Users",
        path: "users",
        icon: <UsersIcon />,
      },
    ],
  },

  {
    title: "Data",
    role: "superadmin",
    links: [
      {
        name: "Encoded Data",
        path: "data",
        icon: <DataIcon />,
      },
    ],
  },
];

export default links;
