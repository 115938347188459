import axios from "axios";

// const BASE_URL = "http://localhost:9000";
const BASE_URL = "https://smea.depedimuscity.com:8020";
// const BASE_URL = "http://192.168.10.11:8020";

function getResults(quarterId) {
  const queryParams = {
    quarterId,
  };

  return axios
    .get(`${BASE_URL}/competency/getAllResults`, { params: queryParams })
    .then((res) => res.data);
}

function getResultsById(id) {
  return axios
    .get(`${BASE_URL}/competency/getAllResponseByUID/${id}`)
    .then((res) => res.data);
}

function deleteResult(id) {
  return axios
    .delete(`${BASE_URL}/competency/deleteResult/${id}`)
    .then((res) => res.data);
}

function filterMELCS(categories) {
  // Create a new object to hold the query parameters
  const queryParams = {
    quarterId: categories?.quarter,
    relatedGradeLevelId: categories?.grade,
    relatedSubjectId: categories?.subject,
  };

  // Check if the component field is defined and not an empty string
  if (categories?.component) {
    queryParams.componentId = categories.component;
  }

  if (categories?.officeId) {
    queryParams.formOfficeId = categories.officeId;
  }

  // Make the Axios request with the constructed query parameters
  return axios
    .get(`${BASE_URL}/competency/filterMelc`, {
      params: queryParams,
    })
    .then((res) => res.data);
}

function getGradeLevelsDropdown(categories) {
  // Create a new object to hold the query parameters
  const queryParams = {
    subjectId: categories?.subject,
    schoolId: categories?.schoolId,
    quarterId: categories?.quarter,
  };

  // Check if the component field is defined and not an empty string
  if (categories?.component) {
    queryParams.componentId = categories.component;
  }

  // Make the Axios request with the constructed query parameters
  return axios
    .get(`${BASE_URL}/competency/getGradesDropdown`, {
      params: queryParams,
    })
    .then((res) => res.data);
}

function addMELCs(data) {
  return axios
    .post(`${BASE_URL}/competency/addMelc`, data)
    .then((res) => res.data);
}

function bulkUploadMELCs(data) {
  return axios
    .post(`${BASE_URL}/competency/uploadMelcs`, data)
    .then((res) => res.data);
}

function updateMELC(id, data) {
  return axios
    .put(`${BASE_URL}/competency/updateMelc/${id}`, data)
    .then((res) => res.data);
}

function deleteMELC(id) {
  return axios
    .delete(`${BASE_URL}/competency/deleteMelc/${id}`)
    .then((res) => res.data);
}

function getAllQuarters() {
  return axios.get(`${BASE_URL}/subject/getAllQuarter`).then((res) => res.data);
}

function addGrade(data) {
  return axios.post(`${BASE_URL}/level/addLevel`, data).then((res) => res.data);
}

function getGrades() {
  return axios.get(`${BASE_URL}/level/getAllLevels`).then((res) => res.data);
}

function updateGrade(id, grade) {
  return axios
    .put(`${BASE_URL}/level/updateLevel/${id}`, grade)
    .then((res) => res.data);
}

function deleteGrade(id) {
  return axios
    .delete(`${BASE_URL}/level/deleteLevel/${id}`)
    .then((res) => res.data);
}

function addSubject(data) {
  return axios
    .post(`${BASE_URL}/subject/addSubject`, data)
    .then((res) => res.data);
}

function getSubjects() {
  return axios
    .get(`${BASE_URL}/subject/getAllSubjects`)
    .then((res) => res.data);
}

function updateSubject(id, subject) {
  return axios
    .put(`${BASE_URL}/subject/updateSubject/${id}`, subject)
    .then((res) => res.data);
}

function deleteSubject(id) {
  return axios
    .delete(`${BASE_URL}/subject/deleteSubject/${id}`)
    .then((res) => res.data);
}

function addComponent(data) {
  return axios
    .post(`${BASE_URL}/subject/addComponent`, data)
    .then((res) => res.data);
}

function getAllComponents() {
  return axios.get(`${BASE_URL}/subject/getComponents`).then((res) => res.data);
}

function updateComponent(id, subject) {
  return axios
    .put(`${BASE_URL}/subject/updateComponent/${id}`, subject)
    .then((res) => res.data);
}

function deleteComponent(id) {
  return axios
    .delete(`${BASE_URL}/subject/deleteComponent/${id}`)
    .then((res) => res.data);
}

function addOffice(data) {
  return axios
    .post(`${BASE_URL}/office/addOffice`, data)
    .then((res) => res.data);
}

function getAllOffices() {
  return axios.get(`${BASE_URL}/office/getAllOffices`).then((res) => res.data);
}

function getOfficeById(officeId) {
  return axios
    .get(`${BASE_URL}/office/getOfficeById/${officeId}`)
    .then((res) => res.data);
}

function updateOffice(id, office) {
  return axios
    .put(`${BASE_URL}/office/updateOffice/${id}`, office)
    .then((res) => res.data);
}

function deleteOffice(id) {
  return axios
    .delete(`${BASE_URL}/office/deleteOffice/${id}`)
    .then((res) => res.data);
}

function addOfficeType(data) {
  return axios
    .post(`${BASE_URL}/office/addOfficeType`, data)
    .then((res) => res.data);
}

function getAllOfficeTypes() {
  return axios
    .get(`${BASE_URL}/office/getAllOfficeTypes`)
    .then((res) => res.data);
}

function updateOfficeType(id, office) {
  return axios
    .put(`${BASE_URL}/office/updateOfficeType/${id}`, office)
    .then((res) => res.data);
}

function deleteOfficeType(id) {
  return axios
    .delete(`${BASE_URL}/office/deleteOfficeType/${id}`)
    .then((res) => res.data);
}

// Miscellaneous APIs
function bulkUpload(data) {
  return axios
    .post(`${BASE_URL}/competency/uploadMelcs`, data)
    .then((res) => res);
}

function formSubmit(form) {
  return axios
    .post(`${BASE_URL}/competency/addFormData`, form)
    .then((res) => res);
}

function formUpdate(id, form) {
  return axios
    .put(`${BASE_URL}/competency/updateFormData/${id}`, form)
    .then((res) => res);
}

function exportSchoolOverallData(schoolId, quarterId) {
  const queryParams = {
    schoolId,
    quarterId,
  };

  return axios
    .get(`${BASE_URL}/competency/schoolConsolidatedData`, {
      params: queryParams,
    })
    .then((res) => res.data);
}

function exportOverallData(quarterId) {
  const queryParams = {
    quarterId,
  };

  return axios
    .get(`${BASE_URL}/competency/allSchoolsConsolidatedData`, {
      params: queryParams,
    })
    .then((res) => res.data);
}

function getSchoolCompletionRate(schoolId, quarterId) {
  const queryParams = {
    schoolId,
    quarterId,
  };

  return axios
    .get(`${BASE_URL}/competency/schoolCompletionRate`, {
      params: queryParams,
    })
    .then((res) => res.data);
}

function getAllSchoolsCompletionRate(quarterId) {
  const queryParams = {
    quarterId,
  };

  return axios
    .get(`${BASE_URL}/competency/allSchoolsCompletionRate`, {
      params: queryParams,
    })
    .then((res) => res.data);
  // return axios
  //   .get(`${BASE_URL}/competency/savedSchoolsCompletionRate`, {
  //     params: queryParams,
  //   })
  //   .then((res) => res.data);
}

function allSchoolsCRPerSubject(quarterId) {
  const queryParams = {
    quarterId,
  };

  return axios
    .get(`${BASE_URL}/competency/allSchoolsCRPerSubject`, {
      params: queryParams,
    })
    .then((res) => res.data);
}

function allSchoolsCRPerGL(quarterId) {
  const queryParams = {
    quarterId,
  };

  return axios
    .get(`${BASE_URL}/competency/allSchoolsCRPerGL`, {
      params: queryParams,
    })
    .then((res) => res.data);
}

function getLeastResponseByOffice(officeId, quarterId) {
  const queryParams = {
    officeId,
    quarterId,
  };

  return axios
    .get(`${BASE_URL}/competency/getLeastResponseByOffice`, {
      params: queryParams,
    })
    .then((res) => res.data);
}

function getLMCWithComments() {
  return axios
    .get(`${BASE_URL}/competency/lmcWithComments`)
    .then((res) => res.data);
}

export default {
  getResults,
  getResultsById,
  deleteResult,

  filterMELCS,
  getGradeLevelsDropdown,
  addMELCs,
  bulkUploadMELCs,
  updateMELC,
  deleteMELC,

  getAllQuarters,

  addGrade,
  getGrades,
  updateGrade,
  deleteGrade,

  addSubject,
  getSubjects,
  updateSubject,
  deleteSubject,

  addComponent,
  getAllComponents,
  updateComponent,
  deleteComponent,

  addOffice,
  getAllOffices,
  getOfficeById,
  updateOffice,
  deleteOffice,

  addOfficeType,
  getAllOfficeTypes,
  updateOfficeType,
  deleteOfficeType,

  bulkUpload,
  formSubmit,
  formUpdate,
  exportSchoolOverallData,
  exportOverallData,
  getSchoolCompletionRate,
  getAllSchoolsCompletionRate,
  allSchoolsCRPerSubject,
  allSchoolsCRPerGL,
  getLeastResponseByOffice,
  getLMCWithComments,
};
