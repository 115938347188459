import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditableTable from "components/smea/Table/EditableTable";
import { useStateContext } from "contexts/ContextProvider";
import ConfirmationModal from "modals/smea/miscellaneous/ConfirmationModal";
import formService from "services/smea/form-service";
import dayjs from "dayjs";

export default function DataTable({
  data,
  setSelectedData,
  loadingState,
  updateTableFunction,
}) {
  const { auth } = useStateContext();
  const [selectedResult, setSelectedResult] = useState();
  const [rowToDelete, setRowToDelete] = useState();

  const [open, setOpen] = useState(false);
  const [promptResponse, setPromptResponse] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [submitKind, setSubmitKind] = useState("");
  const [promptDesc, setPromptDesc] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (rowToDelete) {
      setPromptDesc("Are you sure you want to delete this data?");
      setSubmitKind("delete");
      setOpen(true);
    }
  }, [rowToDelete]);

  useEffect(() => {
    if (!open && submit && promptResponse) {
      setLoading(true);

      formService
        .deleteResult(rowToDelete ? rowToDelete[0]?.id : "")
        .then(() => {
          alert("Data deleted successfully");
          setRowToDelete(null);
          setSubmit(false);
          updateTableFunction();
        })
        .catch((err) => {
          alert(err?.message);
          setRowToDelete(null);
          setSubmit(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [submit, promptResponse]);

  let filteredColumn = [
    // { field: "id", headerName: "ID", width: 70 },
    { field: "formQuarter", headerName: "Quarter", width: 150 },
    { field: "formOfficeName", headerName: "School/Office", width: 200 },
    { field: "formSubject", headerName: "Subject", width: 300 },
    {
      field: "formComponentSub",
      headerName: "Component",
      width: 150,
    },
    { field: "formGradeLevel", headerName: "Grade Level", width: 120 },
    {
      field: "overallMelcaverageMPS",
      headerName: "Average MPS",
      width: 150,
      valueGetter: (params) => {
        const value = parseFloat(params?.value).toFixed(2);
        return value;
      },
    },
    {
      field: "overallMelcaverageSD",
      headerName: "Average SD",
      width: 150,
      valueGetter: (params) => {
        const value = parseFloat(params?.value).toFixed(2);
        return value;
      },
    },
    {
      field: "status",
      headerName: "Submit Type",
      width: 150,
      valueGetter: (params) =>
        // eslint-disable-next-line no-nested-ternary
        params?.value === 1
          ? "Submitted"
          : params?.value === 0
          ? "Saved"
          : "Unknown",
    },
    {
      field: "createdAt",
      headerName: "Date & Time",
      width: 180,
      valueGetter: (params) =>
        dayjs(params?.value).format("MM/DD/YYYY hh:mm:ss A"),
    },
  ];

  setSelectedData(selectedResult);

  if (auth.officeName) {
    filteredColumn = filteredColumn.filter(
      (obj) => obj.field !== "formOfficeName"
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <ConfirmationModal
        open={open}
        handleClose={() => setOpen(false)}
        setPromptResponse={setPromptResponse}
        setSubmit={setSubmit}
        submitKind={submitKind}
        promptDesc={promptDesc}
      />
      <EditableTable
        data={data}
        columns={filteredColumn}
        loading={loading || loadingState}
        rowToDelete={setRowToDelete}
        singleSelect
        selectedData={setSelectedResult}
        height="60vh"
        checkbox
        showSearch
        remove
      />
    </Box>
  );
}
