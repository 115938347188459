import { Box, Typography } from "@mui/material";
import ProgressCircle from "../ProgressCircle";

function StatBox({ title, subtitle, icon, progress, increase }) {
  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          {icon}
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{
              color: "#fff",
              "@media (max-width: 1520px)": {
                fontSize: "30px",
              },
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <ProgressCircle progress={progress} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography
          variant="h5"
          sx={{
            fontSize: subtitle.length > 15 ? "15px" : "18px",
            color: "#f6f6f6",
            fontWeight: "bold",
            "@media (max-width: 1520px)": {
              fontSize: subtitle.length > 15 ? "13px" : "15px",
            },
          }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ fontSize: "15px", color: "black" }}
        >
          {increase}
        </Typography>
      </Box>
    </Box>
  );
}

export default StatBox;
