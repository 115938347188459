import { Box, Button } from "@mui/material";
import EditableTable from "components/smea/Table/EditableTable";
import React from "react";

export default function LMCComments({ data, loadingState }) {
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: () => (
        <Button
          sx={{
            backgroundColor: "green",
            color: "#fff",
            fontSize: "10px",
            fontWeight: "bold",
            padding: "3px 20px",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "lightgray",
              color: "#2f2f2f",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          Update comment
        </Button>
      ),
    },
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "officeName",
      headerName: "School",
      width: 120,
    },
    {
      field: "challenges",
      headerName: "Challenges",
      width: 300,
    },
    {
      field: "ways_forward",
      headerName: "Ways Forward",
      width: 300,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <EditableTable
        data={data}
        columns={columns}
        loading={loadingState}
        height="60vh"
      />
    </Box>
  );
}
