import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import formService from "services/smea/form-service";
import { useEffect, useState } from "react";
import SelectSubject from "components/smea/Textfields/SelectSubject";
import SelectGrade from "components/smea/Textfields/SelectGrade";
import SelectQuarter from "components/smea/Textfields/SelectQuarter";
import { object, string } from "yup";
import SelectComponent from "components/smea/Textfields/SelectComponents";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function AddMELCModal({
  open,
  handleClose,
  updateTableFunction,
}) {
  const [disabled, setDisabled] = useState(false);
  const [disableComponent, setDisableComponent] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      relatedSubjectId: "",
      relatedGradeLevelId: "",
      week: "",
      quarterId: "",
      componentId: "",
      melcNo: "",
      melc: "",
    },

    validationSchema: object().shape({
      relatedSubjectId: string().required("Required"),
      relatedGradeLevelId: string().required("Required"),
      week: string().required("Required"),
      quarterId: string().required("Required"),
      componentId: string(),
      melcNo: string().required("Required"),
      melc: string().required("Required"),
    }),
    onSubmit: () => {
      setLoading(true);
      setError("");

      let formikValues = {};

      if (formik.values.componentId) {
        formikValues = formik.values;
      } else {
        formikValues = { ...formik.values };
        delete formikValues.componentId;
      }

      formService
        .addMELCs(formikValues)
        .then(() => {
          alert("MELC Added");
          updateTableFunction();
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (formik.values) {
      let requiredFields;

      if (
        formik?.values?.relatedSubjectId === 6 ||
        formik?.values?.relatedSubjectId === 8
      ) {
        requiredFields = formik.values;
      } else {
        const { componentId, ...otherValues } = formik.values;
        requiredFields = otherValues;
      }

      const areAllValuesFilled = Object.values(requiredFields).every(
        (value) => !!value
      );

      setDisabled(areAllValuesFilled);
    }
  }, [formik.values]);

  useEffect(() => {
    if (
      formik?.values?.relatedSubjectId === 6 ||
      formik?.values?.relatedSubjectId === 8 ||
      formik?.values?.relatedSubjectId === 9
    ) {
      setDisableComponent(false);
    } else {
      setDisableComponent(true);
    }
  }, [formik?.values]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box
        variant="form"
        component="form"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        sx={style}
      >
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundColor: "blue",
              borderRadius: "10px",
              width: "70%",
              p: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{ ml: 4, fontSize: 25, fontWeight: "bold", color: "#fff" }}
            >
              ADD MELC
            </Typography>
          </Box>
          {error}
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 4,
              mx: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="melcNo"
                  label="MELC No"
                  size="small"
                  disabled={loading}
                  value={formik.values.melcNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={formik.touched.melcNo && Boolean(formik.errors.melcNo)}
                  helperText={formik.touched.melcNo && formik.errors.melcNo}
                  variant="outlined"
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="melc"
                  label="MELC"
                  size="small"
                  disabled={loading}
                  value={formik.values.melc}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={formik.touched.melc && Boolean(formik.errors.melc)}
                  helperText={formik.touched.melc && formik.errors.melc}
                  variant="outlined"
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <SelectSubject
                  label={`Subject ${formik.values.relatedSubjectId ? "" : "*"}`}
                  // label="Subject"
                  name="subject"
                  // disabled={loading}
                  value={formik.values.relatedSubjectId}
                  onChange={(fieldName, selectedValue) => {
                    formik.setFieldValue("relatedSubjectId", selectedValue);
                    formik.setFieldValue("componentId", "");
                  }}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.relatedSubjectId &&
                    Boolean(formik.errors.relatedSubjectId)
                  }
                  helperText={
                    formik.touched.relatedSubjectId &&
                    formik.errors.relatedSubjectId
                  }
                  sx={{
                    width: "100%",
                    pr: 5,
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectComponent
                  label={`Component ${formik.values.componentId ? "" : "*"}`}
                  // label="Subject"
                  name="componentId"
                  // disabled={loading}
                  value={formik.values.componentId}
                  onChange={(fieldName, selectedValue) => {
                    formik.setFieldValue("componentId", selectedValue);
                  }}
                  disable={disableComponent}
                  subId={formik.values.relatedSubjectId}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.componentId &&
                    Boolean(formik.errors.componentId)
                  }
                  helperText={
                    formik.touched.componentId && formik.errors.componentId
                  }
                  sx={{
                    width: "100%",
                    pr: 5,
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGrade
                  label={`Grade Level ${
                    formik.values.relatedGradeLevelId ? "" : "*"
                  }`}
                  // label="Subject"
                  name="grade"
                  // disabled={loading}
                  value={formik.values.relatedGradeLevelId}
                  onChange={(fieldName, selectedValue) => {
                    formik.setFieldValue("relatedGradeLevelId", selectedValue);
                  }}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.relatedGradeLevelId &&
                    Boolean(formik.errors.relatedGradeLevelId)
                  }
                  helperText={
                    formik.touched.relatedGradeLevelId &&
                    formik.errors.relatedGradeLevelId
                  }
                  sx={{
                    width: "100%",
                    pr: 5,
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={`Week ${formik.values.week ? "" : "*"}`}
                  // label="Subject"
                  name="week"
                  // disabled={loading}
                  size="small"
                  value={formik.values.week}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={formik.touched.week && Boolean(formik.errors.week)}
                  helperText={formik.touched.week && formik.errors.week}
                  sx={{
                    width: "100%",
                    pr: 5,
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectQuarter
                  label={`Quarter ${formik.values.quarterId ? "" : "*"}`}
                  // label="Subject"
                  name="week"
                  // disabled={loading}
                  value={formik.values.quarterId}
                  onChange={(fieldName, selectedValue) => {
                    formik.setFieldValue("quarterId", selectedValue);
                  }}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.quarterId && Boolean(formik.errors.quarterId)
                  }
                  helperText={
                    formik.touched.quarterId && formik.errors.quarterId
                  }
                  sx={{
                    width: "100%",
                    pr: 5,
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            p: 2,
            zIndex: 10,
          }}
        >
          <Button
            disabled={!disabled}
            type="submit"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: disabled ? "blue" : "lightgray",
              color: "#fff",
              py: 1,
              width: "10vw",
              minWidth: "100px",
              "&:hover": {
                border: "solid 1px gray",
                backgroundColor: "lightgray",
                color: "#2b2b2b",
                fontWeight: "bold",
              },
            }}
          >
            <AddIcon
              sx={{
                mr: 1,
              }}
            />
            Add MELC
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
