import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { number, object, string } from "yup";
import SelectOffice from "components/smea/Textfields/SelectOffice";
import SelectRole from "components/smea/Textfields/SelectRole";
import accountService from "services/smea/account-service";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function AddMELCModal({
  open,
  handleClose,
  updateTableFunction,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      role: "",
      officeId: null,
    },

    validationSchema: object().shape({
      username: string().required("Required"),
      password: string().required("Required"),
      role: string().required("Required"),
      officeId: number().nullable(),
    }),
    onSubmit: () => {
      setLoading(true);
      setError("");

      const { role, ...otherValues } = formik.values;
      const lowerCasedRole = role.toLowerCase();

      accountService
        .register({ role: lowerCasedRole, ...otherValues })
        .then(() => {
          alert("User Added Successfully");
          updateTableFunction();
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (formik?.values?.role === "admin") {
      const { officeId, ...otherValues } = formik.values;

      const areAllValuesFilled = Object.values(otherValues).every(
        (value) => !!value
      );

      setDisabled(areAllValuesFilled);

      formik.values.officeId = null;
    } else {
      const areAllValuesFilled = Object.values(formik.values).every(
        (value) => !!value
      );

      setDisabled(areAllValuesFilled);
    }
  }, [formik.values]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box
        variant="form"
        component="form"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        sx={style}
      >
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundColor: "blue",
              borderRadius: "10px",
              width: "70%",
              p: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{ ml: 4, fontSize: 25, fontWeight: "bold", color: "#fff" }}
            >
              ADD USER
            </Typography>
          </Box>
          {error}
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 4,
              mx: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="username"
                  label={`Username ${formik?.values?.username ? "" : "*"}`}
                  size="small"
                  disabled={loading}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                  variant="outlined"
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  fullWidth
                  sx={{ pr: 5 }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onKeyPress={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon size={18} sx={{ color: "#606468" }} />
                        ) : (
                          <VisibilityOffIcon
                            size={18}
                            sx={{ color: "#606468" }}
                          />
                        )}
                      </IconButton>
                    ),
                    sx: {
                      borderRadius: "7px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectRole
                  label={`Role ${formik.values.role ? "" : "*"}`}
                  // label="Subject"
                  name="role"
                  width="100%"
                  // disabled={loading}
                  value={formik.values.role}
                  onChange={(fieldName, selectedValue) => {
                    formik.setFieldValue("role", selectedValue);
                  }}
                  onBlur={formik.handleBLur}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                  sx={{
                    width: "100%",
                    pr: 5,
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectOffice
                  label={`Office ${formik.values.officeId ? "" : "*"}`}
                  // label="Subject"
                  name="officeId"
                  width="100%"
                  disabled={formik.values.role === "admin"}
                  value={formik.values.officeId}
                  onChange={(fieldName, selectedValue) => {
                    formik.setFieldValue("officeId", selectedValue);
                  }}
                  onBlur={formik.handleBLur}
                  error={
                    formik.touched.officeId && Boolean(formik.errors.officeId)
                  }
                  helperText={formik.touched.officeId && formik.errors.officeId}
                  sx={{
                    width: "100%",
                    pr: 5,
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black !important",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            p: 2,
            zIndex: 10,
          }}
        >
          <Button
            disabled={!disabled}
            type="submit"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: disabled ? "blue" : "lightgray",
              color: "#fff",
              py: 1,
              width: "10vw",
              minWidth: "100px",
              "&:hover": {
                border: "solid 1px gray",
                backgroundColor: "lightgray",
                color: "#2b2b2b",
                fontWeight: "bold",
              },
            }}
          >
            <AddIcon
              sx={{
                mr: 1,
                color: "#fff",
              }}
            />
            Add User
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
