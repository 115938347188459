import React, { useEffect, useState } from "react";
import ForwardIcon from "@mui/icons-material/Forward";
import UploadIcon from "@mui/icons-material/Upload";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Box, Button, Divider, Typography } from "@mui/material";
import formService from "services/smea/form-service";
import AddMELCModal from "modals/smea/melcs/AddMELCModal";
import UpdateMELCModal from "modals/smea/melcs/UpdateMELCModal";
import PromptModal from "modals/smea/miscellaneous/PromptModal";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import MELCsTable from "./MELCsTable";

export default function MELCs() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [selectedMELC, setSelectedMELC] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [openPromptModal, setOpenPromptModal] = useState(false);
  const [promptMssg, setPromptMssg] = useState([]);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [fileName, setFileName] = useState("");
  const [fileBlob, setFileBlob] = useState(null);

  const handleOpen = (type) => {
    if (type === "add") {
      setOpenAddModal(true);
    } else if (type === "update") {
      setOpenUpdateModal(true);
    }
  };

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/competency/filterMelc`)
      .then((e) => {
        setData(e.data.melcs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  useEffect(() => {
    if (!selectedMELC?.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedMELC]);

  const handleMELCUpload = (event) => {
    const fileInput = event.target;
    // eslint-disable-next-line prefer-destructuring
    const files = fileInput.files;

    // Check if any files were selected
    if (files.length === 0) {
      return; // No file selected, do nothing
    }

    const selectedFile = files[0];

    // Check if the file is an Excel file
    const isExcelFile = /\.(xlsx|xls)$/i.test(selectedFile.name);

    // If it's not an Excel file, show an error message and return
    if (!isExcelFile) {
      alert("Please select an Excel file (.xlsx or .xls).");
      return;
    }

    // Ask for confirmation
    const confirmed = window.confirm(
      "Are you sure you want to upload melcs from this file?"
    );

    // Proceed only if the user confirms
    if (confirmed) {
      setFileName(selectedFile.name);

      // Read the file content as a Blob
      const reader = new FileReader();
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: selectedFile.type });

        setFileBlob(blob);
      };
      reader.readAsArrayBuffer(selectedFile);
    }

    // Clear the file input
    fileInput.value = "";
  };

  useEffect(() => {
    if (fileBlob) {
      setLoading(true);

      const formData = new FormData();

      formData.append("file", fileBlob);

      formService
        .bulkUpload(formData)
        .then((e) => {
          setOpenPromptModal(true);
          setPromptMssg(e.data.data);
          handleGetAll();
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
          setFileBlob(null);
        });
    }
  }, [fileBlob]);

  return (
    <Box
      sx={{
        p: "20px",
        overflowX: "auto",
        width: "100%",
      }}
    >
      <PromptModal
        handleClose={() => setOpenPromptModal(false)}
        open={openPromptModal}
        prompt={promptMssg}
      />
      <AddMELCModal
        handleClose={() => setOpenAddModal(false)}
        open={openAddModal}
        updateTableFunction={handleGetAll}
      />
      <UpdateMELCModal
        handleClose={() => setOpenUpdateModal(false)}
        open={openUpdateModal}
        data={selectedMELC && selectedMELC[0]}
        updateTableFunction={handleGetAll}
      />
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="1rem"
        sx={{ backgroundColor: "#f0f0f0", width: "100%" }}
      >
        <Divider>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: "25px",
            }}
          >
            MELCs
          </Typography>
        </Divider>

        <Box
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          {error}
          <Box
            sx={{
              width: "100%",
            }}
          >
            {auth?.role !== "teacher" && (
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: 3,
                  right: 5,
                  zIndex: 1000,
                  "@media (max-width: 720px)": {
                    position: "relative",
                  },
                }}
              >
                <Typography>{fileName}</Typography>
                <Box
                  htmlFor="melcUpload"
                  mx={2}
                  sx={{
                    backgroundColor: "green",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "3px 20px",
                    borderRadius: "15px",
                    "&:hover": {
                      backgroundColor: "lightgray",
                      color: "#2f2f2f",
                      boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                    },
                  }}
                >
                  <Typography
                    variant="label"
                    component="label"
                    htmlFor="melcUpload"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      fontSize: "small",
                    }}
                  >
                    <UploadIcon sx={{ mr: 1 }} />
                    UPLOAD MELCs
                  </Typography>
                  <input
                    id="melcUpload"
                    type="file"
                    name="melc_upload"
                    onChange={handleMELCUpload}
                    style={{ display: "none" }}
                  />
                </Box>
              </Box>
            )}
            <MELCsTable
              data={data}
              setSelectedData={setSelectedMELC}
              loadingState={loading}
              updateTableFunction={handleGetAll}
            />
          </Box>
        </Box>

        <Divider
          variant="middle"
          sx={{ borderTopWidth: "1px", borderTopColor: "grey" }}
        />
        {auth?.role !== "teacher" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              p: 2,
            }}
          >
            <Button
              onClick={() => handleOpen("add")}
              sx={{
                backgroundColor: "blue",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                },
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                borderRadius: "5px",
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
              }}
            >
              <ForwardIcon sx={{ mr: "10px" }} />
              Add
            </Button>
            <Button
              onClick={() => handleOpen("update")}
              disabled={disabled}
              sx={{
                backgroundColor: "blue",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                },
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                borderRadius: "5px",
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
              }}
            >
              <ForwardIcon sx={{ mr: "10px" }} />
              Update
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
