import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import formService from "services/smea/form-service";

export default function SelectComponent({
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  errorFormik,
  helperText,
  disabled,
  subId,
  gradeId,
  sx = { width: "100%" },
}) {
  const [components, setComponents] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    formService
      .getAllComponents()
      .then((e) => {
        let filteredComponents = e.data;

        if (subId) {
          if (subId === 9 && gradeId === 6) {
            filteredComponents = e.data.filter(
              (component) => component.subId === 8
            );
          } else if (subId === 9) {
            filteredComponents = e.data.filter(
              (component) => component.subId === 8 || component.subId === 9
            );
          } else {
            filteredComponents = e.data.filter(
              (component) => component.subId === subId
            );
          }
        }

        const prefixOrder = ["AFA", "HE", "IA", "ICT"];

        // Custom sorting function
        const customSort = (a, b) => {
          const prefixA = a.componentName.split("-")[0].trim();
          const prefixB = b.componentName.split("-")[0].trim();

          return prefixOrder.indexOf(prefixA) - prefixOrder.indexOf(prefixB);
        };

        // Sort the array using the custom sorting function
        const sortedComponentByGroup = filteredComponents.sort(customSort);

        const sortedComponents = sortedComponentByGroup.sort((a, b) => {
          const nameA = a.componentName.toUpperCase(); // ignore upper and lowercase
          const nameB = b.componentName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names must be equal
        });

        setComponents(sortedComponents);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, [subId, gradeId]);

  const [selectedComponent, setSelectedComponent] = useState(
    components?.find((component) => component.id === value) || null
  );

  useEffect(() => {
    setSelectedComponent(
      components?.find((component) => component.id === value) || null
    );
  }, [components]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newValue) => {
    setAnchorEl(null);
    if (newValue) {
      onChange?.(name, newValue.id || "");
      setSelectedComponent(newValue);
    }
  };

  const handleClear = () => {
    onChange?.(name, ""); // Clear the value
    setSelectedComponent(null);
  };

  return (
    <Box>
      <TextField
        label={
          // eslint-disable-next-line no-nested-ternary
          error ? `Component - ${error}` : disabled ? "Not Applicable" : label
        }
        placeholder={error || (disabled ? "Not Applicable" : placeholder)}
        name={name}
        variant="outlined"
        size="small"
        disabled={error || disabled}
        value={
          selectedComponent && value ? selectedComponent.componentName : ""
        }
        onClick={handleClick}
        onBlur={onBlur}
        error={errorFormik}
        helperText={helperText}
        sx={sx}
        InputProps={{
          endAdornment: (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <>
                  {selectedComponent && (
                    <IconButton edge="end" onClick={handleClear}>
                      <CloseIcon />
                    </IconButton>
                  )}
                  {!disabled && !error && (
                    <IconButton edge="end" onClick={handleClick}>
                      <ArrowDropDownIcon />
                    </IconButton>
                  )}
                </>
              )}
            </>
          ),
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
      >
        {components.map((component) => (
          <MenuItem
            key={component.id}
            onClick={() => handleClose(component)}
            sx={{ width: "100%" }}
          >
            {component.componentName}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
