import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import formService from "services/smea/form-service";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function UpdateOfficeTypeModal({
  open,
  handleClose,
  data,
  updateTableFunction,
}) {
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      Type: data?.Type || "",
    },

    validationSchema: object().shape({
      Type: string().required("Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      setError("");

      formService
        .updateOfficeType(data.id, values)
        .then(() => {
          alert("Office Type Updated");
          updateTableFunction();
          handleClose();
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data) {
      const initialValues = {
        Type: data?.Type || "",
      };
      formik.setValues(initialValues);
    }
  }, [data]);

  useEffect(() => {
    const areAllValuesFilled = Object.values(formik?.values).every(
      (value) => !!value
    );

    setDisabled(areAllValuesFilled);
  }, [formik.values]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box
        variant="form"
        component="form"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        sx={style}
      >
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundColor: "blue",
              borderRadius: "10px",
              width: "70%",
              p: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{ ml: 4, fontSize: 25, fontWeight: "bold", color: "#fff" }}
            >
              UPDATE OFFICE TYPE
            </Typography>
          </Box>
          {error}
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 4,
              mx: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="Type"
                  label={`Office Type ${formik?.values?.Type ? "" : "*"}`}
                  size="small"
                  disabled={loading}
                  value={formik?.values?.Type}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBLur}
                  error={formik?.touched?.Type && Boolean(formik?.errors?.Type)}
                  helperText={formik?.touched?.Type && formik?.errors?.Type}
                  variant="outlined"
                  sx={{ pr: 5 }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            p: 2,
            zIndex: 10,
          }}
        >
          <Button
            disabled={!disabled}
            type="submit"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: disabled ? "blue" : "lightgray",
              color: "#fff",
              py: 1,
              width: "10vw",
              minWidth: "100px",
              "&:hover": {
                border: "solid 1px gray",
                backgroundColor: "lightgray",
                color: "#2b2b2b",
                fontWeight: "bold",
              },
            }}
          >
            <SaveIcon
              sx={{
                mr: 1,
                color: "#fff",
              }}
            />
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
