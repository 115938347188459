import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditableTable from "components/smea/Table/EditableTable";
import formService from "services/smea/form-service";
import ConfirmationModal from "modals/smea/miscellaneous/ConfirmationModal";

export default function MELCsTable({
  data,
  setSelectedData,
  loadingState,
  updateTableFunction,
}) {
  const [selectedMELC, setSelectedMELC] = useState();
  const [rowToDelete, setRowToDelete] = useState();

  const [open, setOpen] = useState(false);
  const [promptResponse, setPromptResponse] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [submitKind, setSubmitKind] = useState("");
  const [promptDesc, setPromptDesc] = useState("");

  const [loading, setLoading] = useState(false);

  setSelectedData(selectedMELC);

  useEffect(() => {
    if (rowToDelete) {
      setPromptDesc("Are you sure you want to delete this data?");
      setSubmitKind("delete");
      setOpen(true);
    }
  }, [rowToDelete]);

  useEffect(() => {
    if (!open && submit && promptResponse) {
      setLoading(true);

      formService
        .deleteMELC(rowToDelete ? rowToDelete[0]?.id : "")
        .then(() => {
          alert("MELC deleted successfully");
          setRowToDelete(null);
          setSubmit(false);
          updateTableFunction();
        })
        .catch((err) => {
          alert(err?.message);
          setRowToDelete(null);
          setSubmit(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [submit, promptResponse]);

  const columns = [
    // { field: "id", headerName: "ID", width: 70 },
    { field: "melcNo", headerName: "MELC No", width: 130 },
    { field: "melc", headerName: "Description", width: 450 },
    {
      field: "week",
      headerName: "Week No",
      width: 130,
    },
    {
      field: "gradeLevel",
      headerName: "Grade Level",
      width: 130,
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 250,
    },
    {
      field: "component",
      headerName: "Component",
      width: 130,
    },
    {
      field: "quarter",
      headerName: "Quarter",
      width: 130,
    },
  ];

  return (
    <Box>
      <ConfirmationModal
        open={open}
        handleClose={() => setOpen(false)}
        setPromptResponse={setPromptResponse}
        setSubmit={setSubmit}
        submitKind={submitKind}
        promptDesc={promptDesc}
      />
      <EditableTable
        data={data}
        columns={columns}
        checkbox
        loading={loading || loadingState}
        singleSelect
        selectedData={setSelectedMELC}
        rowToDelete={setRowToDelete}
        height="60vh"
        remove
      />
    </Box>
  );
}
