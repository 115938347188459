import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
// import accountService from "services/smea/account-service";
import DepedLogo from "../../../assets/images/deped_logo.png";
import ProfileMenu from "../DropDownMenus/ProfileMenu";

export default function MainTopBar({ from }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    navigate("/");
  };

  // const handleRefresh = () => {
  //   accountService.refresh().then((e) => {
  //     console.log(e);
  //   });
  // };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#2b2b2b",
        py: 2,
        "@media (max-width: 700px)": {
          width: from === "form" ? "700px" : "100%",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", ml: 8 }}>
        <Box
          component="img"
          variant="img"
          src={DepedLogo}
          sx={{
            ml: 2,
            width: "60px",
            mr: 2,
            "@media (max-width: 1500px)": {
              width: "60px",
            },
          }}
        />

        <Typography
          sx={{
            fontFamily: "Fira Sans",
            fontWeight: "bold",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          {from === "landing"
            ? "Department of Education (SDO Imus) - Integrated System"
            : "Imus Administrative Bulletin for Integrated Duties and Efficiency (I-ABIDE)"}
          {/* Learning Outcomes Assessment Data Gathering Tool */}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        {location?.pathname !== "/" && (
          <IconButton
            onClick={() => handleNavigate("")}
            sx={{
              mr: 1,
              "&:hover:": {
                color: "#fff",
              },
            }}
          >
            <HomeIcon sx={{ color: "lightgray", fontSize: 30 }} />
          </IconButton>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            mr: 8,
          }}
        >
          <ProfileMenu />
          {/* <Button onClick={() => handleRefresh()}>OH</Button> */}
        </Box>
      </Box>
    </Box>
  );
}
