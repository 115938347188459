/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import EditableTable from "components/smea/Table/EditableTable";
import formService from "services/smea/form-service";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  minHeight: "80vh",
  "@media (min-width: 10px)": {
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    width: "70vw",
  },
};

export default function CRPerSubjectModal({
  open,
  handleClose,
  selectedRow,
  quarter,
}) {
  const [subjectData, setSubjectData] = useState([]);

  const handleGetAll = () =>
    formService
      .exportSchoolOverallData(selectedRow[0]?.id, quarter)
      .then((consolidatedData) => {
        const subjectProgress = {};

        consolidatedData.forEach((ksData) => {
          Object.values(ksData).forEach((ksDetails) => {
            ksDetails.data.forEach((gradeData) => {
              Object.values(gradeData).forEach((gradeDetails) => {
                if (gradeDetails.data) {
                  gradeDetails.data.forEach((subData) => {
                    const { subject } = subData;
                    if (!subjectProgress[subject]) {
                      subjectProgress[subject] = {
                        total: 0,
                        progress: 0,
                      };
                    }
                    subjectProgress[subject].total++;
                    if (subData.overallAverageMPS !== null) {
                      subjectProgress[subject].progress++;
                    }

                    // if (subData.componentsData) {
                    //   subData.componentsData.forEach((component) => {
                    //     subjectProgress[subject].total++;
                    //     if (component.overallAverageMPS !== null) {
                    //       subjectProgress[subject].progress++;
                    //     }
                    //   });
                    // }
                  });
                }
              });
            });
          });
        });

        const completionRateArray = Object.entries(subjectProgress).map(
          ([subject, progress], index) => ({
            id: index + 1,
            subject,
            completionRate: `${(
              (progress.progress / progress.total) *
              100
            ).toFixed(2)}%`,
          })
        );

        setSubjectData(completionRateArray);
      })
      .catch(() => {}).finally;

  useEffect(() => {
    if (selectedRow) {
      handleGetAll();
    }
  }, [selectedRow]);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "subject",
      headerName: "Subject",
      width: 400,
    },
    {
      field: "completionRate",
      headerName: "Completion Rate",
      width: 120,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "absolute",
            right: 20,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              background: "linear-gradient(120deg, #246fc9, #184c8c)",
              borderRadius: "10px",
              width: "70%",
              p: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{
                ml: 4,
                fontSize: 25,
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              COMPLETION RATE PER SUBJECT
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 2,
              mx: 2,
              mb: 2,
            }}
          >
            NOTE: Only <b style={{ marginInline: 4 }}>Submitted</b> data will be
            reflected here
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 1)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderRadius: "10px",
              p: 2,
              mx: 2,
            }}
          >
            <Box
              sx={{
                display: "block",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                  School:
                </Typography>
                <Typography sx={{}}>
                  {selectedRow && selectedRow[0]?.officeName}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                  Completion Rate:
                </Typography>
                <Typography sx={{}}>
                  {selectedRow && selectedRow[0]?.completionRate}
                </Typography>
              </Box>
            </Box>
            {/* {error} */}
            <EditableTable
              data={subjectData}
              columns={columns}
              //   loading={loading}
              height="60vh"
              showSearch
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
