/* eslint-disable no-nested-ternary */
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateContext } from "contexts/ContextProvider";
import links from "./sidebarlinks";

const openedMixin = (theme, width) => ({
  width,
  background: "linear-gradient(60deg, #2b2b2b, #686868, #2b2b2b)",
  // background:
  // "linear-gradient(40deg, rgba(15, 15, 15, 1), rgba(44, 42, 42, 1), rgba(82, 79, 79, 0.9))",
  // background:
  //   "linear-gradient(40deg, rgba(66, 201, 116, 0.9), rgba(59, 152, 184, 1), rgba(26, 214, 164, 0.9))",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  scrollbarWidth: "0.3rem",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0.3rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "gray",
  },
});

const closedMixin = (theme) => ({
  background: "linear-gradient(60deg, #2b2b2b, #686868, #2b2b2b)",
  // background: "linear-gradient(40deg, #e963fd, #8233c5, #274b74)",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  scrollbarWidth: "0.3rem",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0.3rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "gray",
  },
  width: `calc(${theme.spacing(7)} + 1px)`,
  "@media (max-width: 600px)": {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  "@media (max-width: 380px)": {
    width: 0,
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ width, theme, open }) => ({
  width,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "auto",
  scrollbarWidth: "thin",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0.5rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#53FDFD",
  },
  ...(open && {
    ...openedMixin(theme, width),
    "& .MuiDrawer-paper": openedMixin(theme, width),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function SideBar({
  themeProp,
  drawerWidth,
  drawerOpenStatus,
  closeDrawerFunction,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useStateContext();

  const handleNavigate = (link) => {
    navigate(`/${link}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "blue",
        zIndex: 100,
        position: "fixed",
        overflow: "auto",
        scrollbarWidth: "thin",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          width: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#53FDFD",
        },
      }}
    >
      <StyledDrawer
        variant="permanent"
        width={drawerWidth}
        theme={themeProp}
        open={drawerOpenStatus}
      >
        <DrawerHeader theme={themeProp}>
          <IconButton onClick={closeDrawerFunction}>
            {themeProp.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "#fff" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "#fff" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider
          sx={{
            backgroundColor: "#fff",
          }}
        />
        <List
          sx={{
            "@media (min-height: 1920px)": {
              mt: 6,
            },
          }}
        >
          {links.map(
            (item) =>
              (item.role === "superadmin" || // Display items for superadmin to all users
                (auth?.role === "admin" && item.role === "admin") || // Display items for admin to admin users
                (auth?.role !== "teacher" && item.role === "users") ||
                (auth?.role === "teacher" && item.role === "teacher")) && (
                <ListItem
                  key={item.title}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  <Box
                    sx={{ ml: drawerOpenStatus ? 2 : 0, my: 2, color: "#fff" }}
                  >
                    <Typography>
                      {drawerOpenStatus ? (
                        item.title
                      ) : (
                        <Divider
                          sx={{
                            backgroundColor: "#fff",
                          }}
                        />
                      )}
                    </Typography>
                  </Box>

                  {item.links.map((link) => (
                    <Tooltip
                      title={!drawerOpenStatus && link.name}
                      placement="right"
                    >
                      <ListItemButton
                        onClick={() =>
                          handleNavigate(
                            auth?.role === "teacher" &&
                              link.path === "dashboard"
                              ? "school-dashboard"
                              : auth?.role === "admin" &&
                                link.path === "dashboard"
                              ? link.path
                              : link.path
                          )
                        }
                        sx={{
                          minHeight: 48,
                          justifyContent: drawerOpenStatus
                            ? "initial"
                            : "center",
                          px: 2.5,

                          backgroundColor:
                            `/${link.path}` === location.pathname
                              ? "lightgray"
                              : `/${link.path}` === "/dashboard" &&
                                auth?.role === "teacher" &&
                                location.pathname === "/school-dashboard"
                              ? "lightgray"
                              : "transparent",
                          "&:hover": {
                            backgroundColor: "gray",
                            color: "black",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: "#fff",
                            minWidth: 0,
                            mr: drawerOpenStatus ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "10px",
                              color:
                                `/${link.path}` === location.pathname
                                  ? "black"
                                  : `/${link.path}` === "/dashboard" &&
                                    auth?.role === "teacher" &&
                                    location.pathname === "/school-dashboard"
                                  ? "black"
                                  : "#fff",
                            }}
                          >
                            {link.icon}
                          </Typography>
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: drawerOpenStatus ? 1 : 0,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color:
                                `/${link.path}` === location.pathname
                                  ? "black"
                                  : `/${link.path}` === "/dashboard" &&
                                    auth?.role === "teacher" &&
                                    location.pathname === "/school-dashboard"
                                  ? "black"
                                  : "#fff",
                            }}
                          >
                            {link.name}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </Tooltip>
                  ))}
                </ListItem>
              )
          )}
        </List>
      </StyledDrawer>
    </Box>
  );
}

export default SideBar;
