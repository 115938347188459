/* eslint-disable no-plusplus */
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import StatBox from "components/smea/StatBox";
import CircularProgress from "@mui/material/CircularProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PercentIcon from "@mui/icons-material/Percent";
import BackpackIcon from "@mui/icons-material/Backpack";
import React, { useEffect, useState } from "react";
import { useStateContext } from "contexts/ContextProvider";
import formService from "services/smea/form-service";
import { isNaN } from "formik";
import LeastMasteredCompetencyModal from "modals/smea/displayCards/school/LeastMasteredCompetencyModal";
import CompletionRatePerSubjectModal from "modals/smea/displayCards/school/CompletionRatePerSubjectModal";
import CRPerGradeLevelModal from "modals/smea/displayCards/school/CRPerGradeLevelModal";
import ConsolidatedDataModal from "modals/smea/displayCards/school/ConsolidatedDataModal";
import SelectQuarter from "components/smea/Textfields/SelectQuarter";
import SchoolDataTable from "./school-results-table";

function TeacherDashboard() {
  const [completionRate, setCompletionRate] = useState("");
  const [consolidatedData, setConsolidatedData] = useState([]);
  const [subjectCRCount, setSubjectCRCount] = useState(0);
  const [subjectData, setSubjectData] = useState([]);
  const [dataResults, setDataResults] = useState([]);
  const [LMCs, setLMCs] = useState([]);
  const [LMCWithComments, setLMCWithComments] = useState([]);

  const [CRPerGradeLevelOpen, setCRPerGradeLevelOpen] = useState(false);
  const [CRPerSubjectOpen, setCRPerSubjectOpen] = useState(false);
  const [consolidatedDataOpen, setConsolidatedDataOpen] = useState(false);
  const [LMCOpen, setLMCOpen] = useState(false);

  const [quarter, setQuarter] = useState(3);

  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const { auth } = useStateContext();

  const handleGetCompletionRate = () =>
    formService.getSchoolCompletionRate(auth.officeId, quarter).then((e) => {
      setCompletionRate(e.completionRate);
    });

  const handleGetSchoolConsolidatedData = () =>
    formService.exportSchoolOverallData(auth.officeId, quarter).then((e) => {
      setConsolidatedData(e);
    });

  const handleGetResults = () =>
    formService.getResults(quarter).then((results) => {
      const filteredResults = results.filter(
        (result) => result.formOfficeId === auth?.officeId
      );

      if (filteredResults.length === 0 && auth?.role === "admin") {
        setDataResults(results);
      } else {
        setDataResults(filteredResults);
      }
    });

  const handleGetLMCs = () =>
    formService
      .getLeastResponseByOffice(auth.officeId, quarter)
      .then((results) => {
        setLMCs(results);
      });

  const handleGetLMCsWithComment = () =>
    formService.getLMCWithComments().then((results) => {
      setLMCWithComments(results.data);
    });

  const overall = () => {
    let overallMps = 0;

    // eslint-disable-next-line array-callback-return
    dataResults?.map((res) => {
      overallMps += res.overallMelcaverageMPS;
    });

    const avgOverallMPS = overallMps / dataResults.length;

    return isNaN(avgOverallMPS) ? 0 : `${parseFloat(avgOverallMPS).toFixed(2)}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");

      try {
        if (quarter) {
          await Promise.all([
            handleGetCompletionRate(),
            handleGetSchoolConsolidatedData(),
            handleGetResults(),
            handleGetLMCs(),
            handleGetLMCsWithComment(),
          ]);
        } else {
          setCompletionRate();
          setConsolidatedData([]);
          setDataResults([]);
          setLMCs([]);
          setLMCWithComments([]);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [quarter]);

  const handleGetSubjectsData = () => {
    const subjectProgress = {};

    consolidatedData.forEach((ksData) => {
      Object.values(ksData).forEach((ksDetails) => {
        ksDetails.data.forEach((gradeData) => {
          Object.values(gradeData).forEach((gradeDetails) => {
            if (gradeDetails.data) {
              gradeDetails.data.forEach((subData) => {
                const { subject } = subData;
                if (!subjectProgress[subject]) {
                  subjectProgress[subject] = {
                    total: 0,
                    progress: 0,
                  };
                }
                subjectProgress[subject].total++;
                if (subData.overallAverageMPS !== null) {
                  subjectProgress[subject].progress++;
                }

                // if (subData.componentsData) {
                //   subData.componentsData.forEach((component) => {
                //     subjectProgress[subject].total++;
                //     if (component.overallAverageMPS !== null) {
                //       subjectProgress[subject].progress++;
                //     }
                //   });
                // }
              });
            }
          });
        });
      });
    });

    const completionRateArray = Object.entries(subjectProgress).map(
      ([subject, progress], index) => ({
        id: index + 1,
        subject,
        completionRate: `${((progress.progress / progress.total) * 100).toFixed(
          2
        )}%`,
      })
    );

    let subjectsWithData = 0;

    completionRateArray.forEach((subData) => {
      if (subData.completionRate === "100.00%") {
        subjectsWithData += 1;
      }
    });

    setSubjectCRCount(subjectsWithData);

    setSubjectData(completionRateArray);
  };

  useEffect(() => {
    handleGetSubjectsData();
  }, [consolidatedData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f0f0f0",
        overflowY: "hidden",
        px: 7,
        py: 2,
      }}
    >
      <CRPerGradeLevelModal
        open={CRPerGradeLevelOpen}
        handleClose={() => setCRPerGradeLevelOpen(false)}
        consolidatedData={consolidatedData}
        loading={loading}
        error={error}
      />
      <CompletionRatePerSubjectModal
        open={CRPerSubjectOpen}
        handleClose={() => setCRPerSubjectOpen(false)}
        loading={loading}
        error={error}
        subjectData={subjectData}
      />
      <ConsolidatedDataModal
        open={consolidatedDataOpen}
        handleClose={() => setConsolidatedDataOpen(false)}
        consolidatedData={consolidatedData}
        loading={loading}
        error={error}
      />
      <LeastMasteredCompetencyModal
        open={LMCOpen}
        handleClose={() => setLMCOpen(false)}
        consolidatedData={consolidatedData}
        LMCWithCommentsData={LMCWithComments}
        loadingState={loading}
        error={error}
      />
      {error}
      {loading}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
          my: 2,
        }}
      >
        <Box>
          <Typography
            sx={{ textAlign: "left", fontSize: "30px", fontWeight: "bold" }}
          >
            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
            {"Welcome to the School's Dashboard!"}
          </Typography>
          <Typography
            sx={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}
          >
            {auth?.officeName}
          </Typography>
        </Box>
        <Box sx={{ my: 2 }}>
          Specify Quarter:
          <SelectQuarter
            placeholder="Select Quarter"
            name="quarter"
            value={quarter}
            onChange={(fieldName, selectedValue) => {
              setQuarter(selectedValue);
            }}
            sx={{
              width: "100%",
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black !important",
                },
              },
            }}
          />
        </Box>
      </Box>
      <Box sx={{ width: "100%", my: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "4vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "4vw",
            }}
          >
            <Tooltip
              title={`${!quarter ? "(No Data) Quarter is not specified" : ""}`}
              placement="top"
            >
              <Box
                onClick={() => {
                  if (quarter) {
                    setCRPerGradeLevelOpen(true);
                  }
                }}
                sx={{
                  background: "linear-gradient(120deg, #cf1515, #9319a6)",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                  borderRadius: "20px",
                  width: "18vw",
                  minWidth: "290px",
                  cursor: "pointer",
                  transition: "all .3s",
                  py: 2,
                  "&:hover": {
                    boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                  },
                  "@media (max-width: 1550px)": {
                    // width: "18vw",
                    minWidth: "230px",
                    // maxWidth: "350px",
                  },
                }}
              >
                <StatBox
                  title={loading ? <CircularProgress /> : completionRate || 0}
                  subtitle="Completion Rate"
                  icon={
                    <PercentIcon
                      sx={{ color: "lightgray", fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Tooltip>
            <Tooltip
              title={`${!quarter ? "(No Data) Quarter is not specified" : ""}`}
              placement="top"
            >
              <Box
                onClick={() => {
                  if (quarter) {
                    setCRPerSubjectOpen(true);
                  }
                }}
                sx={{
                  background: "linear-gradient(120deg, #246fc9, #184c8c)",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                  borderRadius: "20px",
                  width: "18vw",
                  minWidth: "290px",
                  cursor: "pointer",
                  transition: "all .3s",
                  py: 2,
                  "&:hover": {
                    boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                  },
                  "@media (max-width: 1550px)": {
                    // width: "18vw",
                    minWidth: "230px",
                    // maxWidth: "350px",
                  },
                }}
              >
                <StatBox
                  title={
                    loading ? <CircularProgress /> : `${subjectCRCount || 0}/10`
                  }
                  subtitle="Subjects"
                  icon={
                    <BackpackIcon
                      sx={{ color: "lightgray", fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "4vw",
            }}
          >
            <Tooltip
              title={`${!quarter ? "(No Data) Quarter is not specified" : ""}`}
              placement="top"
            >
              <Box
                onClick={() => {
                  if (quarter) {
                    setConsolidatedDataOpen(true);
                  }
                }}
                sx={{
                  background: "linear-gradient(120deg, #f09116, #a8650d)",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                  borderRadius: "20px",
                  width: "18vw",
                  minWidth: "290px",
                  cursor: "pointer",
                  transition: "all .3s",
                  py: 2,
                  "&:hover": {
                    boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                  },
                  "@media (max-width: 1550px)": {
                    // width: "18vw",
                    minWidth: "230px",
                    // maxWidth: "350px",
                  },
                }}
              >
                <StatBox
                  title={loading ? <CircularProgress /> : overall()}
                  subtitle="Average MPS"
                  icon={
                    <ListAltIcon
                      sx={{ color: "lightgray", fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Tooltip>
            <Tooltip
              title={`${!quarter ? "(No Data) Quarter is not specified" : ""}`}
              placement="top"
            >
              <Box
                onClick={() => {
                  if (quarter) {
                    setLMCOpen(true);
                  }
                }}
                sx={{
                  background: "linear-gradient(120deg, #27bf13, #207a14)",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                  borderRadius: "20px",
                  width: "18vw",
                  minWidth: "290px",
                  cursor: "pointer",
                  transition: "all .3s",
                  py: 2,
                  "&:hover": {
                    boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                  },
                  "@media (max-width: 1550px)": {
                    // width: "18vw",
                    minWidth: "230px",
                    // maxWidth: "350px",
                  },
                }}
              >
                <StatBox
                  title={
                    // eslint-disable-next-line no-nested-ternary
                    loading ? (
                      <CircularProgress />
                    ) : LMCs?.length !== 0 ? (
                      parseFloat(LMCs[0]?.mps).toFixed(2)
                    ) : (
                      0
                    )
                  }
                  subtitle="Least Mastered Competency"
                  icon={
                    <FilterListIcon
                      sx={{ color: "lightgray", fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: "black", m: 2 }} />
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{ textAlign: "left", fontSize: "25px", fontWeight: "bold" }}
        >
          {`School's Encoded Data`}
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <SchoolDataTable data={dataResults} />
      </Box>
    </Box>
  );
}

export default TeacherDashboard;
