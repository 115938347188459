/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import ExcelJS from "exceljs";

function toCamelCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

const downloadData = (dataProp, officeName) => {
  if (dataProp?.length === 0 || !dataProp) {
    // eslint-disable-next-line no-alert
    alert("No data available to export.");
    return;
  }

  const workbook = new ExcelJS.Workbook();

  // Iterate over each KSA
  dataProp.forEach((ksaData, ksaIndex) => {
    const ksaName = Object.keys(ksaData)[0];
    const ksaSheet = workbook.addWorksheet(ksaName);

    const schoolNameCell = ksaSheet.getCell("A1");
    ksaSheet.mergeCells("A1:G1");
    schoolNameCell.value = officeName;
    schoolNameCell.alignment = { vertical: "middle", horizontal: "center" };
    schoolNameCell.font = { size: 16, bold: true, color: { argb: "FFFFFFFF" } };
    schoolNameCell.border = {
      top: { style: "thick" },
      right: { style: "thick" },
      bottom: { style: "thick" },
      left: { style: "thick" },
    };
    schoolNameCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF244062" },
    };
    ksaSheet.getRow(1).height = 35;

    const mainHeaderCell = ksaSheet.getCell("A2");
    ksaSheet.mergeCells("A2:G2");
    mainHeaderCell.value = `Key Stage Area ${ksaIndex + 1}`;
    mainHeaderCell.alignment = { vertical: "middle", horizontal: "center" };
    mainHeaderCell.font = { size: 16, bold: true };
    mainHeaderCell.border = {
      top: { style: "thick" },
      right: { style: "thick" },
      bottom: { style: "thick" },
      left: { style: "thick" },
    };
    mainHeaderCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF92D050" },
    };
    ksaSheet.getRow(2).height = 35;

    // Add headers
    const headerRow = ksaSheet.addRow([
      "Grade Level",
      "Subject",
      "Average MPS",
      "Least Mastered MPS",
      "Least Mastered Competencies",
      "Average MPS Per Grade",
      "KSA Average",
    ]);

    // Styles for header
    headerRow.values.forEach((value, index) => {
      const cell = ksaSheet.getCell(`${String.fromCharCode(64 + index)}3`);
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        right: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF92cddc" },
      };
    });

    // font styles and height for header cells
    headerRow.eachCell((cell) => {
      cell.font = { size: 12, bold: true };
    });
    ksaSheet.getRow(3).height = 30;

    const columnWidths = [10, 40, 10, 20, 50, 25, 15];

    // Initialize map to track starting row for each grade level
    const gradeStartRowMap = new Map();

    const KSATotal = ksaData[ksaName].ksaOverallAverage;

    // Iterate over each grade level data
    let rowIndex = 4; // Start from row 3 (after the header row)
    ksaData[ksaName].data.forEach((gradeData) => {
      const gradeLevel = Object.keys(gradeData)[0];
      const camelCaseGradeLevel = toCamelCase(gradeLevel);
      const { overallAvgMPSPerGrade } = gradeData[gradeLevel];
      gradeStartRowMap.set(camelCaseGradeLevel, rowIndex);

      const proficiencyLevelText = (avg) => {
        let proflevel = "";

        if (avg === null) {
          proflevel = null;
        } else if (avg >= 90 && avg <= 100) {
          proflevel = `${avg}\nHighly Proficient`;
        } else if (avg >= 75 && avg < 90) {
          proflevel = `${avg}\nProficient`;
        } else if (avg >= 50 && avg < 75) {
          proflevel = `${avg}\nNearly Proficient`;
        } else if (avg >= 25 && avg < 50) {
          proflevel = `${avg}\nLow Proficient`;
        } else if (avg >= 0 && avg < 25) {
          proflevel = `${avg}\nNot Proficient`;
        } else {
          proflevel = null;
        }

        return proflevel;
      };

      gradeData[gradeLevel]?.data?.forEach((grade) => {
        // if subject has components
        if (grade.componentsData) {
          const rowComponentMainData = [
            camelCaseGradeLevel,
            ` ${grade.subject}`,
            proficiencyLevelText(grade.overallAverageMPS),
          ];

          const subWithComponents = ksaSheet.addRow(rowComponentMainData);
          rowIndex++;

          subWithComponents.getCell(2).alignment = { vertical: "middle" };
          subWithComponents.font = { bold: true };
          subWithComponents.getCell(2).font = { size: 12, bold: true };
          subWithComponents.getCell(3).alignment = {
            wrapText: true,
            horizontal: "center",
            vertical: "middle",
          };

          subWithComponents.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFf2f2f2" },
          };

          grade.componentsData.forEach((data) => {
            const { subject, overallAverageMPS, mps, least_mastered } = data;

            let mpsValues;
            if (mps !== null) {
              mpsValues = mps
                ?.map((item) => Object.values(item).join(": "))
                .join("\n");
            } else {
              mpsValues = null;
            }

            let leastMasteredValues;
            // Extracting values from least_mastered array
            if (least_mastered !== null) {
              leastMasteredValues = least_mastered
                .map((item) => Object.values(item).join(": "))
                .join("\n");
            } else {
              leastMasteredValues = null;
            }

            const rowComponentData = [
              camelCaseGradeLevel,
              `   ${subject}`,
              proficiencyLevelText(overallAverageMPS),
              mpsValues,
              leastMasteredValues,
              proficiencyLevelText(overallAvgMPSPerGrade),
              proficiencyLevelText(KSATotal),
            ];

            const row = ksaSheet.addRow(rowComponentData);

            row.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFf2f2f2" },
            };

            row.getCell(2).alignment = { vertical: "middle" };
            row.getCell(2).font = { vertical: "middle", italic: true };
            row.getCell(3).alignment = {
              wrapText: true,
              horizontal: "center",
              vertical: "middle",
            };
            row.getCell(3).font = {
              italic: true,
            };
            row.getCell(4).alignment = {
              wrapText: true,
              horizontal: "center",
              vertical: "middle",
            };
            row.getCell(5).alignment = { wrapText: true, vertical: "middle" };

            const rowRange = ksaSheet.getRow(rowIndex);

            rowRange.eachCell({ includeEmpty: true }, (cell) => {
              cell.border = {
                left: { style: "thin" },
                right: { style: "thin" },
              };
            });
            rowIndex++;
          });
        } else {
          const { subject, overallAverageMPS, mps, least_mastered } = grade;

          // Extracting values from mps array
          let mpsValues;
          if (mps !== null) {
            mpsValues = mps
              ?.map((item) => Object.values(item).join(": "))
              .join("\n");
          } else {
            mpsValues = null;
          }

          let leastMasteredValues;
          // Extracting values from least_mastered array
          if (least_mastered !== null) {
            leastMasteredValues = least_mastered
              .map((item) => Object.values(item).join(": "))
              .join("\n");
          } else {
            leastMasteredValues = null;
          }

          const rowData = [
            camelCaseGradeLevel,
            subject,
            proficiencyLevelText(overallAverageMPS),
            mpsValues,
            leastMasteredValues,
            proficiencyLevelText(overallAvgMPSPerGrade),
            proficiencyLevelText(KSATotal),
          ];

          const row = ksaSheet.addRow(rowData);

          row.getCell(2).alignment = { vertical: "middle" };
          row.getCell(3).alignment = {
            wrapText: true,
            horizontal: "center",
            vertical: "middle",
          };
          row.getCell(3).font = {
            italic: true,
          };
          row.getCell(4).alignment = {
            wrapText: true,
            horizontal: "center",
            vertical: "middle",
          };
          row.getCell(5).alignment = { wrapText: true, vertical: "middle" };

          const rowRange = ksaSheet.getRow(rowIndex);

          rowRange.eachCell({ includeEmpty: true }, (cell) => {
            cell.border = {
              left: { style: "thin" },
              right: { style: "thin" },
            };
          });
          rowIndex++;
        }
      });
    });

    // for merging whole KSA rows for the "KS Average"
    const lastRowIndex = rowIndex - 1;
    const mergeRange = `G4:G${lastRowIndex}`;
    ksaSheet.mergeCells(mergeRange);
    const KSAverageCell = ksaSheet.getCell("G4");
    KSAverageCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFF200" },
    };
    KSAverageCell.font = { size: 14, bold: true };
    KSAverageCell.alignment = {
      wrapText: true,
      horizontal: "center",
      vertical: "middle",
    };

    // Set column widths based on the maximum length of characters
    columnWidths.forEach((width, index) => {
      ksaSheet.getColumn(index + 1).width = width + 6;
    });

    // Merge cells for the Grade Level column
    gradeStartRowMap.forEach((startRow, gradeName) => {
      const subjectsCount = ksaData[ksaName].data.find(
        (gradeData) => Object.keys(gradeData)[0] === gradeName.toLowerCase()
      )[gradeName.toLowerCase()].data?.length;

      const subjects = ksaData[ksaName].data.find(
        (gradeData) => Object.keys(gradeData)[0] === gradeName.toLowerCase()
      )[gradeName.toLowerCase()].data;

      let componentsCount = 0;

      subjects.forEach((subject) => {
        if (subject.componentsData) {
          componentsCount += subject.componentsData.length;
        }
        // if (subject.subject === "MAPEH") {
        //   const filteredComponents = components.filter(
        //     (component) => component.subId === 6
        //   );

        //   componentsCount += filteredComponents.length;
        // } else if (
        //   subject.subject === "Edukasyong Pangtahanan at Pangkabuhayan"
        // ) {
        //   const filteredComponents = components.filter(
        //     (component) => component.subId === 8
        //   );

        //   componentsCount += filteredComponents.length;
        // } else if (subject.subject === "Technology and Livelihood Education") {
        //   let filteredComponents;

        //   if (gradeName.toLowerCase() === "grade 6") {
        //     filteredComponents = components.filter(
        //       (component) => component.subId === 8
        //     );
        //   } else {
        //     filteredComponents = components.filter(
        //       (component) => component.subId === 9
        //     );
        //   }

        //   componentsCount += filteredComponents.length;
        // }
      });

      const endRow = startRow + subjectsCount + componentsCount - 1;
      const gradeRange = `A${startRow}:A${endRow}`;
      const totalAvgRange = `F${startRow}:F${endRow}`;
      ksaSheet.mergeCells(gradeRange);
      ksaSheet.mergeCells(totalAvgRange);

      for (let i = startRow; i <= endRow; i++) {
        for (let j = 1; j <= 7; j++) {
          // border for 7 columns
          const cell = ksaSheet.getCell(`${String.fromCharCode(64 + j)}${i}`);
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }

      // Apply top border to the first row of each grade data
      ksaSheet.getRow(startRow).eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          ...cell.border,
          top: { style: "medium" },
        };
      });

      // Apply bottom border to the last row of each grade data
      ksaSheet.getRow(endRow).eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          ...cell.border,
          bottom: { style: "medium" },
        };
      });

      // Get the merged cell and set the grade level's data alignment to center
      const mergedCell = ksaSheet.getCell(`A${startRow}`);
      const totalMPSAvgmergedCell = ksaSheet.getCell(`F${startRow}`);
      mergedCell.alignment = { vertical: "middle", horizontal: "center" };
      totalMPSAvgmergedCell.alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center",
      };
    });
  });

  const date = new Date();
  const options = { month: "2-digit", day: "2-digit", year: "numeric" };
  const formattedDate = date
    .toLocaleDateString("en-US", options)
    .replace(/\//g, "-");

  const getAbbreviation = (name) => {
    const words = name.split(" ");
    const initials = words.map((word) => {
      if (word.toLowerCase() === "alapan") {
        return `${toCamelCase(word)}`;
      }
      if (word.toLowerCase() === "anabu") {
        return `${toCamelCase(word)}`;
      }
      if (word.toLowerCase() === "palico") {
        return `${toCamelCase(word)}`;
      }
      if (word.toLowerCase() === "tinabunan") {
        return `${toCamelCase(word)}`;
      }
      if (word.toLowerCase() === "toclong") {
        return `${toCamelCase(word)}`;
      }
      if (word.toUpperCase() === "I") {
        return `-${word.toUpperCase()}-`;
      }
      if (word.charAt(0).toUpperCase() === "I") {
        if (word.charAt(1).toUpperCase() === "I") {
          return `-${word.toUpperCase()}-`;
        }
      }
      return word.charAt(0).toUpperCase();
    });
    return initials.join("");
  };

  const filename = `${
    officeName ? `${getAbbreviation(officeName)}_` : ""
  }Overall_Data_Report_${formattedDate}.xlsx`;

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE browser
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  downloadData,
};
