/* eslint-disable no-plusplus */
import { Box, Button, Tooltip, Typography } from "@mui/material";
import StatBox from "components/smea/StatBox";
import CircularProgress from "@mui/material/CircularProgress";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SchoolIcon from "@mui/icons-material/School";
import BackpackIcon from "@mui/icons-material/Backpack";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import React, { useEffect, useState } from "react";
import formService from "services/smea/form-service";
import Barchart from "components/smea/Charts/Barchart";
import DivisionMPSModal from "modals/smea/displayCards/admin/DivisionMPSModal";
import CRPerGradeLevelModal from "modals/smea/displayCards/admin/CRPerGradeLevelModal";
import SchoolsCompletionRateModal from "modals/smea/displayCards/admin/SchoolsCompletionRateModal";
import { isNaN } from "formik";
import CompletionRatePerSubjectModal from "modals/smea/displayCards/admin/CompletionRatePerSubjectModal";
import { useStateContext } from "contexts/ContextProvider";
import "./admin-dashboard.css";
import SelectQuarter from "components/smea/Textfields/SelectQuarter";
import useAxiosPrivate from "contexts/interceptors/axios";

function AdminDashboard() {
  const {
    auth,
    schoolsCR,
    setSchoolsCR,
    schoolsCRCount,
    setSchoolsCRCount,
    subjectData,
    setSubjectData,
    subjectCRCount,
    setSubjectCRCount,
    gradeLevelData,
    setGradeLevelData,
    gradeLevelCRCount,
    setGradeLevelCRCount,
  } = useStateContext();
  const axiosPrivate = useAxiosPrivate();

  const [results, setResults] = useState([]);
  const [consolidatedData, setConsolidatedData] = useState([]);
  const [showLabel, setShowLabel] = useState(true);
  const [quarter, setQuarter] = useState(3);
  const [CRPerGradeLevelOpen, setCRPerGradeLevelOpen] = useState(false);
  const [CRPerSubjectOpen, setCRPerSubjectOpen] = useState(false);
  const [divisionMPSOpen, setDivisionMPSOpen] = useState(false);
  const [schoolsCROpen, setSchoolsCROpen] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [CRLoading, setCRLoading] = useState(false);

  const handleGetAllResults = () => {
    if (quarter) {
      // formService
      //   .getResults(quarter)
      axiosPrivate
        .get(`/competency/getAllResults`, { params: { quarterId: quarter } })
        .then((res) => {
          setResults(res.data);
        })
        .catch((err) => setError(err.message));
    } else {
      setResults([]);
    }
  };

  const handleGetAllConsolidatedData = () => {
    if (quarter) {
      // formService.exportOverallData(quarter);
      axiosPrivate
        .get(`/competency/allSchoolsConsolidatedData`, {
          params: { quarterId: quarter },
        })
        .then((res) => {
          setConsolidatedData(res);
        })
        .catch((err) => setError(err.message));
    } else {
      setConsolidatedData([]);
    }
  };

  const overall = () => {
    let overallMps = 0;

    // eslint-disable-next-line array-callback-return
    results?.map((res) => {
      overallMps += res.overallMelcaverageMPS;
    });

    const avgOverallMPS = overallMps / results.length;

    return isNaN(avgOverallMPS) ? 0 : `${parseFloat(avgOverallMPS).toFixed(2)}`;
  };

  useEffect(() => {
    setLoading(true);
    setError("");

    Promise.all([handleGetAllResults(), handleGetAllConsolidatedData()])
      .then(() => setLoading(false))
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [quarter]);

  const handleGetAllSchoolCR = () => {
    if (CRLoading === false) {
      setCRLoading(true);
      setError("");

      formService
        .getAllSchoolsCompletionRate(quarter)
        .then((e) => {
          setSchoolsCRCount(e.schoolsCRCount);
          setSchoolsCR(e.allSchoolsCR);
        })
        .catch((err) => setError(err.message))
        .finally(() => {
          setCRLoading(false);
        });
    }
  };

  const handleGetAllCRPerSubject = () => {
    if (CRLoading === false) {
      setCRLoading(true);
      setError("");

      formService
        .allSchoolsCRPerSubject(quarter)
        .then((e) => {
          setSubjectCRCount(e.subjectsCompletedCount);
          setSubjectData(e.subjectsCR);
        })
        .catch((err) => setError(err.message))
        .finally(() => {
          setCRLoading(false);
        });
    }
  };

  const handleGetAllCRPerGL = () => {
    if (CRLoading === false) {
      setCRLoading(true);
      setError("");

      formService
        .allSchoolsCRPerGL(quarter)
        .then((e) => {
          setGradeLevelCRCount(e.GLCompletedCount);
          setGradeLevelData(e.gradeLevelCR);
        })
        .catch((err) => setError(err.message))
        .finally(() => {
          setCRLoading(false);
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f0f0f0",
        px: 7,
        py: 2,
      }}
    >
      <CRPerGradeLevelModal
        open={CRPerGradeLevelOpen}
        handleClose={() => setCRPerGradeLevelOpen(false)}
        loading={loading}
        error={error}
        gradeLevelData={gradeLevelData}
      />
      <CompletionRatePerSubjectModal
        open={CRPerSubjectOpen}
        handleClose={() => setCRPerSubjectOpen(false)}
        loading={loading}
        error={error}
        subjectData={subjectData}
      />
      <DivisionMPSModal
        open={divisionMPSOpen}
        handleClose={() => setDivisionMPSOpen(false)}
        consolidatedData={consolidatedData}
        loading={loading}
        error={error}
      />
      <SchoolsCompletionRateModal
        open={schoolsCROpen}
        handleClose={() => setSchoolsCROpen(false)}
        data={schoolsCR}
        loadingState={loading}
        quarter={quarter}
      />
      {error}
      {loading}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
          my: 2,
        }}
      >
        <Box>
          <Typography
            sx={{ textAlign: "left", fontSize: "30px", fontWeight: "bold" }}
          >
            {auth?.role === "admin"
              ? "Welcome to the Admin Dashboard!"
              : "Welcome to the DepEd Official's Dashboard!"}
          </Typography>
          <Typography
            sx={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}
          >
            {auth?.role === "admin" ? "Admin" : auth?.username}
          </Typography>
        </Box>
        <Box sx={{ my: 2 }}>
          Specify Quarter:
          <SelectQuarter
            placeholder="Select Quarter"
            name="quarter"
            value={quarter}
            onChange={(fieldName, selectedValue) => {
              setQuarter(selectedValue);
            }}
            sx={{
              width: "100%",
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black !important",
                },
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          my: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "4vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "4vw",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={`${
                  schoolsCR.length === 0
                    ? "Schools Completion Rate Not Loaded"
                    : "View Schools Completion Rate Table"
                }`}
                placement="top"
              >
                <Box
                  onClick={() => {
                    if (schoolsCR.length !== 0 && !CRLoading) {
                      setSchoolsCROpen(true);
                    }
                  }}
                  sx={{
                    background: "linear-gradient(120deg, #cf1515, #9319a6)",
                    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                    borderRadius: "20px",
                    width: "18vw",
                    minWidth: "290px",
                    // maxWidth: "350px",
                    cursor: "pointer",
                    transition: "all .3s",
                    py: 2,
                    mb: 2,
                    "&:hover": {
                      boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                    },
                    "@media (max-width: 1550px)": {
                      // width: "18vw",
                      minWidth: "230px",
                      // maxWidth: "350px",
                    },
                  }}
                >
                  <StatBox
                    title={
                      // eslint-disable-next-line no-nested-ternary
                      loading || CRLoading ? (
                        <CircularProgress />
                      ) : schoolsCR.length !== 0 ? (
                        `${schoolsCRCount || 0}/35`
                      ) : (
                        <Typography sx={{ fontSize: "27px" }}>
                          Uncalculated
                        </Typography>
                      )
                    }
                    subtitle="Schools"
                    icon={
                      <SchoolIcon
                        sx={{ color: "lightgray", fontSize: "26px" }}
                      />
                    }
                  />
                </Box>
              </Tooltip>

              <Tooltip
                title={!quarter && "Quarter not specified"}
                placement="bottom"
                backgroundColor="red"
              >
                <button
                  id="btn-calculate"
                  className="btn-calculate"
                  type="submit"
                  onClick={handleGetAllSchoolCR}
                  disabled={CRLoading || !quarter}
                  style={{
                    cursor: CRLoading ? "default" : "pointer",
                    color: CRLoading ? "#364d60" : "#fff",
                  }}
                >
                  <span>All Schools Completion Rate</span>
                  <span>Calculate</span>
                </button>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={`${
                  subjectData.length === 0
                    ? "Completion Rate Per Subject Not Loaded"
                    : "View Schools Completion Rate Per Subject Table"
                }`}
                placement="top"
              >
                <Box
                  onClick={() => {
                    if (subjectData.length !== 0 && !CRLoading) {
                      setCRPerSubjectOpen(true);
                    }
                  }}
                  sx={{
                    background: "linear-gradient(120deg, #246fc9, #184c8c)",
                    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                    borderRadius: "20px",
                    width: "18vw",
                    minWidth: "290px",
                    // maxWidth: "350px",
                    cursor: "pointer",
                    transition: "all .3s",
                    py: 2,
                    mb: 2,
                    "&:hover": {
                      boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                    },
                    "@media (max-width: 1550px)": {
                      // width: "18vw",
                      minWidth: "230px", // 230px
                      // maxWidth: "350px",
                    },
                  }}
                >
                  <StatBox
                    title={
                      // eslint-disable-next-line no-nested-ternary
                      loading || CRLoading ? (
                        <CircularProgress />
                      ) : subjectData.length !== 0 ? (
                        `${subjectCRCount || 0}/10`
                      ) : (
                        <Typography sx={{ fontSize: "27px" }}>
                          Uncalculated
                        </Typography>
                      )
                    }
                    subtitle="Subjects"
                    icon={
                      <ListAltIcon
                        sx={{ color: "lightgray", fontSize: "26px" }}
                      />
                    }
                  />
                </Box>
              </Tooltip>
              <Tooltip
                title={!quarter && "Quarter not specified"}
                placement="bottom"
                backgroundColor="red"
              >
                <button
                  id="btn-calculate"
                  className="btn-calculate"
                  type="submit"
                  onClick={handleGetAllCRPerSubject}
                  disabled={CRLoading || !quarter}
                  style={{
                    cursor: CRLoading ? "default" : "pointer",
                    color: CRLoading ? "#364d60" : "#fff",
                  }}
                >
                  <span>All Schools Completion Rate Per Subject</span>
                  <span>Calculate</span>
                </button>
              </Tooltip>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "4vw",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={`${
                  gradeLevelData.length === 0
                    ? "Completion Rate Per Grade Level Not Loaded"
                    : "View Schools Completion Rate Per Grade Level Table"
                }`}
                placement="top"
              >
                <Box
                  onClick={() => {
                    if (gradeLevelData.length !== 0 && !CRLoading) {
                      setCRPerGradeLevelOpen(true);
                    }
                  }}
                  sx={{
                    background: "linear-gradient(120deg, #f09116, #a8650d)",
                    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                    borderRadius: "20px",
                    width: "18vw",
                    minWidth: "290px",
                    // maxWidth: "350px",
                    cursor: "pointer",
                    transition: "all .3s",
                    py: 2,
                    mb: 2,
                    "&:hover": {
                      boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                    },
                    "@media (max-width: 1550px)": {
                      // width: "18vw",
                      minWidth: "230px",
                      // maxWidth: "350px",
                    },
                  }}
                >
                  <StatBox
                    title={
                      // eslint-disable-next-line no-nested-ternary
                      loading || CRLoading ? (
                        <CircularProgress />
                      ) : gradeLevelData.length !== 0 ? (
                        `${gradeLevelCRCount || 0}/10`
                      ) : (
                        <Typography sx={{ fontSize: "27px" }}>
                          Uncalculated
                        </Typography>
                      )
                    }
                    subtitle="Grade Levels"
                    progress={26}
                    icon={
                      <BackpackIcon
                        sx={{ color: "lightgray", fontSize: "26px" }}
                      />
                    }
                  />
                </Box>
              </Tooltip>
              <Tooltip
                title={!quarter && "Quarter not specified"}
                placement="bottom"
                backgroundColor="red"
              >
                <button
                  id="btn-calculate"
                  className="btn-calculate"
                  type="submit"
                  onClick={handleGetAllCRPerGL}
                  disabled={CRLoading || !quarter}
                  style={{
                    cursor: CRLoading ? "default" : "pointer",
                    color: CRLoading ? "#364d60" : "#fff",
                  }}
                >
                  <span>All Schools Completion Rate Per Grade Level</span>
                  <span>Calculate</span>
                </button>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                onClick={() => {
                  if (!CRLoading) {
                    setDivisionMPSOpen(true);
                  }
                }}
                sx={{
                  background: "linear-gradient(120deg, #27bf13, #207a14)",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                  borderRadius: "20px",
                  width: "18vw",
                  minWidth: "290px",
                  // maxWidth: "350px",
                  cursor: "pointer",
                  transition: "all .3s",
                  py: 2,
                  mb: 2,
                  "&:hover": {
                    boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                  },
                  "@media (max-width: 1550px)": {
                    // width: "18vw",
                    minWidth: "230px",
                    // maxWidth: "350px",
                  },
                }}
              >
                <StatBox
                  title={
                    loading || CRLoading ? <CircularProgress /> : overall()
                  }
                  subtitle="Division MPS"
                  icon={
                    <PersonAddIcon
                      sx={{ color: "lightgray", fontSize: "26px" }}
                    />
                  }
                />
              </Box>
              <Box
                sx={{
                  height: "50px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {CRLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: 2,
          }}
        >
          <Box>
            <Typography sx={{ mr: 2 }}>
              Computing All Schools Completion Rate...
            </Typography>
            <Typography sx={{ mr: 2 }}>This may take a while</Typography>
          </Box>
          <CircularProgress />
        </Box>
      ) : (
        schoolsCR.length !== 0 && (
          <Box
            sx={{
              mt: 4,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "25px",
                  fontWeight: "bold",
                }}
              >
                Schools Completion Rate Graph
              </Typography>
              <Button onClick={() => setShowLabel(!showLabel)}>
                {showLabel ? "Hide Label" : "Show Label"}
              </Button>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflowX: "none",
                mb: 8,
                scrollbarWidth: "0.3rem",
                scrollbarHeight: "0.3rem",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  height: "0.3rem",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "gray",
                },
                "@media (max-width: 1400px)": {
                  overflowX: "auto",
                },
              }}
            >
              <Barchart
                data={schoolsCR}
                showLabel={showLabel}
                loadingState={loading}
              />
            </Box>
          </Box>
        )
      )}
    </Box>
  );
}

export default AdminDashboard;
