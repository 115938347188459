/* eslint-disable no-alert */
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditNoteIcon from "@mui/icons-material/EditNote";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useStateContext } from "contexts/ContextProvider";
import MainTopBar from "components/smea/Topbar/MainTopBar";
import KagawaranLogo from "assets/images/kagawaran.png";
import TranparencyLogo from "assets/images/transparency_logo.png";
import DepedLogo from "assets/images/deped_logo.png";
import SubmitFormModal from "modals/i-abide/SubmitFormModal";
import formService from "services/i-abide/form-service";
import SummaryModal from "modals/i-abide/SummaryModal";
import IABIDETable from "./i-abide-table";

export default function IABIDEForm() {
  const { auth } = useStateContext();
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openFillUpForm, setOpenFillUpForm] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);

  const handleGetAll = () => {
    setLoading(true);

    formService
      .getIABIDEData()
      .then((response) => {
        setData(response?.forms);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // backgroundColor: "lightgray",
        background:
          "linear-gradient(40deg, rgba(239, 98, 98, 0.9), rgba(59, 152, 184, 1), rgba(26, 214, 164, 0.9))",
        height: "100vh",
        width: "100vw",
        overflow: "auto",
        // overflowX: "hidden",
        scrollbarWidth: "thin",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          width: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#53FDFD",
        },
        "@media (max-width: 715px)": {
          display: "block",
        },
      }}
    >
      <SubmitFormModal
        open={openFillUpForm}
        handleClose={() => setOpenFillUpForm(false)}
        updateTableFunction={handleGetAll}
      />
      <SummaryModal
        open={openSummary}
        handleClose={() => setOpenSummary(false)}
      />
      <MainTopBar from="form" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "95vw",
          "@media (max-height: 1080px)": {
            width: "90vw",
          },
          minWidth: "600px",
          my: 8,
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          boxShadow: "3px 2px 20px 3px rgba(65, 65, 65, 0.6)",
          borderRadius: "10px",
          px: 6,
          py: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            position: "relative",
          }}
        >
          {selectedTab === 1 && (
            <IconButton
              onClick={() => setSelectedTab(0)}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Box
            className="logo"
            component="img"
            src={DepedLogo}
            alt="Logo"
            width="100px"
            mb={2}
          />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#323232",
              // textShadow:
              //   "2px 2px 2px rgba(0, 0, 0, 1), -2px -2px 2px rgba(0, 0, 0, 1)",
            }}
          >
            {auth.officeName || "Administrator"}
          </Typography>
        </Box>

        <Divider
          sx={{
            backgroundColor: "blue",
            borderRadius: "10px",
            py: 0.1,
            my: 2,
          }}
        />

        <Box
          sx={{
            p: 2,
            my: "2vh",
            "@media (max-height: 1080px)": {
              my: "3vh",
            },
            backgroundColor: "#FFF",
            boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.8)",
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 15,
              right: 20,
              zIndex: 1000,
            }}
          >
            <Button
              onClick={() => setOpenSummary(true)}
              sx={{
                backgroundColor: "#1976d2",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "3px 20px",
                borderRadius: "5px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <VisibilityIcon sx={{ mr: 2 }} />
              See Summary
            </Button>
            {auth.role !== "teacher" && (
              <Button
                onClick={() => setOpenFillUpForm(true)}
                sx={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "3px 20px",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "lightgray",
                    color: "#2f2f2f",
                    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <EditNoteIcon sx={{ mr: 2 }} />
                Fill & Upload Form
              </Button>
            )}
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              // height: "40vh",
              width: "100%",
              // "@media (max-height: 1080px)": {
              //   height: "60vh",
              // },
            }}
          >
            <IABIDETable
              data={data}
              loadingState={loading}
              updateTableFunction={handleGetAll}
            />
          </Box>
          <Typography
            sx={{
              color: "red",
              fontWeight: "bolder",
              fontSize: "15px",
              mb: 4,
            }}
          >
            {error}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          // display: "inline-block",
          width: "90vw",
        }}
      >
        <Box sx={{ display: "inline-block", flex: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>
            Toclong I-C Imus City, Cavite 4103
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            imus.city@deped.gov.ph
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>depedimuscity.com</Typography>
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <Box
            component="img"
            src={KagawaranLogo}
            mr={2}
            sx={{
              width: "5vh",
              "@media (max-height: 1080px)": {
                width: "10vh",
              },
            }}
          />
          <Box
            component="img"
            src={TranparencyLogo}
            sx={{
              width: "5vh",
              "@media (max-height: 1080px)": {
                width: "10vh",
              },
            }}
          />
        </Box>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography sx={{ fontWeight: "bold" }}>
          © DepEd Imus Division | 2023.
        </Typography>
      </Box>
      {/* <SnackbarComponent
        open={openSuccess}
        onClose={handleCloseSuccess}
        severity="success"
        message="Encoding Successful."
      />
      <SnackbarComponent
        open={openError}
        onClose={handleCloseError}
        severity="error"
        message={error}
      /> */}
    </Box>
  );
}
