import React, { useEffect, useState } from "react";
import ForwardIcon from "@mui/icons-material/Forward";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Box, Button, Divider, Typography } from "@mui/material";
// import formService from "services/smea/form-service";
import AddGradeModal from "modals/smea/gradeLevel/AddGradeModal";
import UpdateGradeModal from "modals/smea/gradeLevel/UpdateGradeModal";
import useAxiosPrivate from "contexts/interceptors/axios";
import GradeTable from "./GradeTable";

export default function GradeLevels() {
  const axiosPrivate = useAxiosPrivate();

  const [gradeData, setGradeData] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const handleOpen = (type) => {
    if (type === "add") {
      setOpenAddModal(true);
    } else if (type === "update") {
      setOpenUpdateModal(true);
    }
  };

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    // formService
    //   .getGrades()
    axiosPrivate
      .get(`/level/getAllLevels`)
      .then((e) => {
        setGradeData(e.data);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  useEffect(() => {
    if (!selectedGrade?.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedGrade]);

  return (
    <Box
      sx={{
        p: "20px",
      }}
    >
      <AddGradeModal
        handleClose={() => setOpenAddModal(false)}
        open={openAddModal}
        updateTableFunction={handleGetAll}
      />
      <UpdateGradeModal
        handleClose={() => setOpenUpdateModal(false)}
        open={openUpdateModal}
        data={selectedGrade && selectedGrade[0]}
        updateTableFunction={handleGetAll}
      />
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="1rem"
        sx={{ backgroundColor: "rgba(240, 240, 240, 1)" }}
      >
        <Divider>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: "25px",
            }}
          >
            Grade Levels
          </Typography>
        </Divider>
        <Box>
          {error}
          <GradeTable
            data={gradeData}
            setSelectedData={setSelectedGrade}
            loadingState={loading}
            updateTableFunction={handleGetAll}
          />
        </Box>

        <Divider
          variant="middle"
          sx={{ borderTopWidth: "1px", borderTopColor: "grey" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Box>
            <Button
              onClick={() => handleOpen("add")}
              sx={{
                backgroundColor: "blue",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                },
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                borderRadius: "5px",
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
              }}
            >
              <ForwardIcon sx={{ mr: "10px" }} />
              Add
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => handleOpen("update")}
              disabled={disabled}
              sx={{
                backgroundColor: "blue",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                },
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                borderRadius: "5px",
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
              }}
            >
              <ForwardIcon sx={{ mr: "10px" }} />
              Update
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
