/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from "react";
import "./register.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import accountService from "../../../services/smea/account-service";
import regValidation, { initialReg } from "../../../schema/register";
import googleLogo from "../../../assets/images/google_logo.png";

function RegistrationPage() {
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const handleHome = () => {
    navigate("/");
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passError, setPassError] = useState("");

  const [isChecked, setChecked] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const formik = useFormik({
    initialValues: initialReg,
    validationSchema: regValidation,
    onSubmit: async () => {
      setError("");
      setLoading(true);
      const regDetails = { ...formik.values, role: "client" };
      await accountService
        .register(regDetails)
        .then(() => {
          formik.resetForm();
          handleHome();
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (formik.values.password !== formik.values.confirmPassword) {
      setPassError("Password does not match");
    } else {
      setPassError("");
    }
  }, [formik.values.password, formik.values.confirmPassword]);

  useEffect(() => {
    if (
      isChecked &&
      formik.values.email !== "" &&
      formik.values.firstname !== "" &&
      formik.values.lastname !== "" &&
      formik.values.contactNumber !== "" &&
      passError === ""
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [isChecked, formik.values, passError]);

  const flexCenter = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Grid className="register">
      {/* <Box
        sx={{
          position: "fixed",
          top: "50%",
          right: "-8%",
          transform: "translate(-50%, -50%)",
          "@media (max-width: 1210px)": {
            display: "none",
          },
        }}
      >
        <Typography>asd</Typography>
      </Box> */}
      <Box
        sx={{
          "@media (max-width: 1210px)": {
            height: "80%",
            width: "80%",
            px: 6,
          },
        }}
      >
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                boxShadow: "2px -3px 15px -1px rgba(64, 118, 224, 0.64)",
              },
              "@media (max-width: 1210px)": {
                ml: 0,
              },
              "@media (min-width: 1210px)": {
                ml: "5rem",
              },
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontWeight: "bold",
                mt: 8,
                mb: 2,
              }}
            >
              Create Account
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>{error}</Typography>
              <TextField
                id="email"
                placeholder="Email Address"
                variant="outlined"
                size="small"
                disabled={loading}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                InputProps={{ style: { color: "#fff" } }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#fff",
                  },
                  mb: 2,
                }}
              />
              <TextField
                id="firstname"
                placeholder="First Name"
                variant="outlined"
                size="small"
                disabled={loading}
                value={formik.values.firstname}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                helperText={formik.touched.firstname && formik.errors.firstname}
                fullWidth
                InputProps={{ style: { color: "#fff" } }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#fff",
                  },
                  mb: 2,
                }}
              />
              <TextField
                id="lastname"
                placeholder="Last Name"
                variant="outlined"
                size="small"
                disabled={loading}
                value={formik.values.lastname}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
                fullWidth
                InputProps={{ style: { color: "#fff" } }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#fff",
                  },
                  mb: 2,
                }}
              />
              <TextField
                id="middlename"
                placeholder="Middlename (Optional)"
                variant="outlined"
                size="small"
                disabled={loading}
                value={formik.values.middlename}
                onChange={formik.handleChange}
                error={
                  formik.touched.middlename && Boolean(formik.errors.middlename)
                }
                helperText={
                  formik.touched.middlename && formik.errors.middlename
                }
                fullWidth
                InputProps={{ style: { color: "#fff" } }}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#fff",
                  },
                  mb: 2,
                }}
              />
              <TextField
                id="contactNumber"
                placeholder="Contact Number"
                variant="outlined"
                size="small"
                disabled={loading}
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactNumber &&
                  Boolean(formik.errors.contactNumber)
                }
                helperText={
                  formik.touched.contactNumber && formik.errors.contactNumber
                }
                fullWidth
                sx={{
                  mb: 2,
                }}
                // InputLabelProps={{
                //   shrink: Boolean(formik.values.contactNumber),
                //   sx: {
                //     "&.Mui-focused": {
                //       color: "#fff", // Set the color for the focused state
                //     },
                //     color: "#fff",
                //     ml: formik.values.contactNumber ? 0 : 5,
                //   },
                // }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography sx={{ fontWeight: "bold", color: "#fff" }}>
                        +63
                      </Typography>
                    </InputAdornment>
                  ),
                  sx: {
                    color: "#fff",
                  },
                }}
              />

              <TextField
                id="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                fullWidth
                size="small"
                disabled={loading}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#fff",
                  },
                  mb: 2,
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onKeyPress={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityIcon size={18} sx={{ color: "#606468" }} />
                      ) : (
                        <VisibilityOffIcon
                          size={18}
                          sx={{ color: "#606468" }}
                        />
                      )}
                    </IconButton>
                  ),
                  sx: {
                    color: "#fff",
                    borderRadius: "7px",
                  },
                }}
              />
              <TextField
                id="confirmPassword"
                placeholder="Confirm Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                fullWidth
                size="small"
                disabled={loading}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#fff",
                  },
                  mb: 2,
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowConfPassword(!showConfPassword)}
                      onKeyPress={() => setShowConfPassword(!showConfPassword)}
                    >
                      {showConfPassword ? (
                        <VisibilityIcon size={18} sx={{ color: "#606468" }} />
                      ) : (
                        <VisibilityOffIcon
                          size={18}
                          sx={{ color: "#606468" }}
                        />
                      )}
                    </IconButton>
                  ),
                  sx: {
                    color: "#fff",
                    borderRadius: "7px",
                  },
                }}
              />
              <Typography>{passError}</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    "&.Mui-checked": {
                      color: "#53FDFD", // Color when the checkbox is checked
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#fff", // Color of the checkbox itself
                    },
                  }}
                />
                <Box
                  sx={{
                    my: 2,
                    ...flexCenter,
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                  }}
                >
                  <Typography>I agree to the</Typography>
                  <Typography
                    sx={{
                      mx: 0.5,
                      fontWeight: "bold",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    Terms of Service
                  </Typography>
                  <Typography>and</Typography>
                  <Typography
                    sx={{
                      mx: 0.5,
                      fontWeight: "bold",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  transition: "all 300ms ease-in-out",
                  backgroundColor: !disable ? "transparent" : "lightgray",
                  width: "100%",
                  mb: 2,
                  borderRadius: "20px",
                }}
              >
                <Button
                  id="login-btn"
                  type="submit"
                  variant="contained"
                  disabled={disable}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "20px",
                    color: "black",
                    fontSize: "15px",
                    padding: "8px 5px",
                    margintop: "15px",
                    width: "100%",
                    height: "40px",
                    "&:hover": {
                      backgroundColor: "#4076EO",
                      color: "#fff",
                      fontWeight: "bolder",
                    },
                  }}
                >
                  Create
                </Button>
              </Box>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "20px",
                  color: "black",
                  fontSize: "15px",
                  padding: "8px 5px",
                  margintop: "15px",
                  width: "100%",
                  height: "40px",
                  mb: 2,
                  "&:hover": {
                    backgroundColor: "#4076EO",
                    color: "#fff",
                    fontWeight: "bolder",
                  },
                }}
              >
                <Box
                  variant="img"
                  component="img"
                  src={googleLogo}
                  alt="googlelogo"
                  height="100%"
                  mr={2}
                />
                Sign Up with Google
              </Button>

              <Box sx={{ ...flexCenter, mb: 4 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    m: 0,
                  }}
                >
                  Already have an account?
                </Typography>
                <Link to="/login">
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      m: "0 0 0 2px",
                      fontWeight: "bold",
                    }}
                  >
                    Login
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Grid>
  );
}

export default RegistrationPage;
